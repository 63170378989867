import { useMediaQuery } from "@react-hook/media-query";
import { DatePicker, Modal, Spin, TimePicker, message } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import circletick from "../../Assests/Circle_Check.svg";
import greentick from "../../Assests/Greentick.svg";
import popupimage from "../../Assests/Popupimage.svg";
import { CountryData } from "../datas/formdata";
import axios from "axios";
import { baseURL } from "../../Services/api";
import { DemoWrap } from "./style";
import moment from 'moment';
dayjs.extend(customParseFormat);

export const BookaDemo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();

  const [selectedTime, setSelectedTime] = React.useState(null);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleClearDate = () => {
    setSelectedDate(null);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Tab",
      ].includes(event.key);
    const isCtrlKey = event.key === "Control" || event.ctrlKey || event.metaKey;
    if (!isNumericKey && !isCtrlKey) {
      event.preventDefault();
    }
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    console.log("Submit Function Triggered", data);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/bookademo-form/` + `${data.emailid}`,
          {
            firstname: data.fname,
            lastname: data.lname,
            phoneno: data.phoneNumber,
            companyname: data.companyName,
            jobtitle: data.jobtitle,
            noofemployees: data.noOfEmployees,
            availabledate: selectedDate,
            country: data.country,
            time: data.time,
          }
        );

        if (response.data.status) {
          setIsModalOpen(true);
          setTimeout(() => {
            setIsModalOpen(false);
          }, 3000);
          setLoading(false);
          reset();
          handleClearDate();
          setSelectedCountry("");
          setSelectedTime(null);
        } else {
          messageApi.open({
            type: "error",
            content: "Something went wrong",
          });
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Something went wrong",
        });
        reset();
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Internal Server Error",
      });
      reset();
    }
  };

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountryData = CountryData.find(
      (country) => country.name === selectedCountryName
    );

    if (selectedCountryData) {
      setSelectedCountry(selectedCountryData.dial_code);
    }
  };

  const disablePastDates = (current) => {
    return current.isBefore(dayjs().startOf("day"));
  };

  return (
    <>
      <DemoWrap>
        <Container fluid className="demo-fullcontain">
          <Row>
            <Col lg={6} md={12}>
              <Container
                className={` ${isMobile ? "demo-containmob" : "demo-contain"}`}
              >
                <div className={` ${isMobile ? "demo-fulltext" : ""}`}>
                  <div className="text1 pb-2">
                    Unlock Your Effective Management{" "}
                  </div>
                  <div className={` ${isMobile ? "text2-mob" : "text2 pb-2"}`}>
                    {" "}
                    Book Your <br />
                    Personalized <br /> Demo Today{" "}
                  </div>
                  <ul
                    className={` ${
                      isMobile
                        ? "list-stylemob ms-3"
                        : "ms-3 pt-4 list-style ps-4"
                    }`}
                  >
                    <li>Live Platform Walkthrough</li>
                    <li>Interactive Q&A Session</li>
                    <li>Personalized Recommendations</li>
                    <li>Tailored Solutions</li>
                  </ul>
                </div>
              </Container>
            </Col>
            <Col
              lg={6}
              md={12}
              className={` ${isMobile ? "demo-mobbgimg" : "demo-bgimg"}`}
            >
              <Row>
                <Col>
                  {contextHolder}
                  <Container className="shadow-box my-4 py-4 px-4">
                    <Spin spinning={loading}>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col lg={6} className="mb-3">
                            <Form.Group
                              className="text-start"
                              controlId="formFirstName"
                            >
                              <Form.Label className="label-custom">
                                First Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                {...register("fname", {
                                  required: "This field is required",
                                })}
                                name="fname"
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={15}
                              />
                              <span className="error-msg">
                                {errors.fname?.message}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-3">
                            <Form.Group
                              className="text-start"
                              controlId="formLastName"
                            >
                              <Form.Label className="label-custom">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                {...register("lname", {
                                  required: "This field is required",
                                })}
                                name="lname"
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={15}
                              />
                              <span className="error-msg">
                                {errors.lname?.message}
                              </span>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group
                          className="mb-3 text-start"
                          controlId="formEmailID"
                        >
                          <Form.Label className="label-custom">
                            Email ID
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="emailid"
                            {...register("emailid", {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Please enter a valid email",
                              },
                            })}
                          />
                          <span className="error-msg">
                            {errors.emailid?.message}
                          </span>
                        </Form.Group>
                        <Row>
                          <Col lg={12} md={12} className="mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label className="label-custom">
                                Company Name
                              </Form.Label>
                              <Form.Control
                                {...register("companyName")}
                                type="text"
                                placeholder=""
                                name="companyName"
                                className="placeholder-style"
                                maxLength={15}
                              />
                              <p className="error-msg pt-2">
                                {errors.companyName?.message}
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={12} className="mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label className="label-custom">
                                Country
                              </Form.Label>
                              <Form.Select
                                {...register("country", {
                                  required: "This field is required",
                                })}
                                name="country"
                                onChange={(e) => {
                                  handleCountryChange(e);
                                  clearErrors("country");
                                }}
                                autoComplete="off"
                              >
                                <option value="">Select Country</option>
                                {CountryData.map((country, index) => (
                                  <option key={index} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                              </Form.Select>
                              <p className="error-msg pt-2">
                                {errors.country?.message}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={12} className="mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label className="label-custom">
                                Phone Number
                              </Form.Label>
                              <InputGroup>
                                {selectedCountry && (
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    {selectedCountry}
                                  </InputGroup.Text>
                                )}
                                <Form.Control
                                  className="placeholder-style"
                                  name="phoneNumber"
                                  {...register("phoneNumber", {
                                    required: "This field is required",
                                  })}
                                  onKeyDown={(event) => handleKeyDown(event)}
                                  onPaste={(event) => handlePaste(event)}
                                  autoComplete="off"
                                  maxLength={10}
                                />
                              </InputGroup>
                            </Form.Group>
                            {errors.phoneNumber && (
                              <span className="error-msg">
                                This field is required
                              </span>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} lg={6} className="mb-3">
                            <Form.Group
                              className="text-start"
                              controlId="formNumberOfEmployees"
                            >
                              <Form.Label className="label-custom">
                                No of Employees
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                onKeyDown={handleKeyDown}
                                onPaste={(event) => handlePaste(event)}
                                {...register("noOfEmployees", {
                                  required: "This field is required",
                                })}
                                name="noOfEmployees"
                                maxLength={8}
                              />
                              <span className="error-msg">
                                {errors.noOfEmployees?.message}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={12} className="mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label className="label-custom">
                                Job title
                              </Form.Label>
                              <Form.Control
                                {...register("jobtitle", {
                                  required: "This field is required",
                                })}
                                type="text"
                                placeholder=""
                                name="jobtitle"
                                className="placeholder-style"
                                maxLength={15}
                              />
                              <p className="error-msg pt-2">
                                {errors.jobtitle?.message}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col md={12} lg={6} className="mb-3">
                            <Form.Group
                              className="text-start"
                              controlId="formDateAvailable"
                            >
                              <Form.Label className="label-custom">
                                Date Available
                              </Form.Label>
                              <br />
                              <Controller
                                name="date"
                                control={control}
                                rules={{ required: "This field is required" }}
                                render={({ field }) => (
                                  <DatePicker
                                    {...field}
                                    disabledDate={disablePastDates}
                                    style={{ width: "100%" }}
                                    onChange={(date, dateString) => {
                                      const localDate = date ? moment(dateString).format('DD/MM/YYYY') : '';
                                      field.onChange(date);
                                      setSelectedDate(localDate);
                                    }}
                                   
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.date && (
                              <span className="error-msg">
                                This field is required
                              </span>
                            )}
                          </Col>
                          <Col md={12} lg={6} className="mb-3">
                            <Form.Group controlId="formTimeAvailable">
                              <Form.Label className="label-custom">
                                Time Available
                              </Form.Label>
                              <br />
                              <Controller
                                control={control}
                                name="time"
                                rules={{
                                  required: "Time Available is required",
                                }}
                                render={({ field }) => (
                                  <TimePicker
                                    style={{ width: "100%" }}
                                    format="HH:mm A"
                                    value={selectedTime}
                                    onChange={(time, timeString) => {
                                      field.onChange(timeString);
                                      handleTimeChange(time);
                                    }}
                                  />
                                )}
                              />
                              {errors.time && (
                                <span className="error-msg">
                                  {errors.time.message}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="form-group d-flex">
                          <input
                            type="checkbox"
                            {...register("tandc", {
                              required: "This field is required",
                            })}
                            className="form-check-input"
                            id="tandc"
                            name="tandc"
                          />
                          <label htmlFor="tandc" className="ps-2 label-agree">
                            By checking this box, you agree to the{" "}
                            <span style={{ color: "blue" }}>
                              {" "}
                              <Link
                                to="/termsandconditions"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                Terms and Conditions{" "}
                              </Link>
                            </span>{" "}
                            of TymeplusHR
                          </label>
                          {errors.tandc && (
                            <div className="invalid-feedback">
                              {errors.tandc.message}
                            </div>
                          )}
                        </div>
                        <span className="error-msg">
                          {errors.tandc?.message}
                        </span>
                        <div className="d-flex justify-content-center align-items-center">
                          <button type="submit" className="theme-filldbtn mt-4">
                            Submit
                          </button>{" "}
                        </div>
                      </Form>
                    </Spin>
                  </Container>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Row>
            <Col className="bgimg" lg={12}>
              <img src={popupimage} alt="popup" />
              <div className="d-flex justify-content-center">
                <img
                  src={isMobile ? circletick : greentick}
                  alt="tick"
                  className="successtick"
                />
              </div>
            </Col>
            <Col className="content py-4 my-4 text-center" lg={12}>
              <p className="contact-para">Submitted Successfully!</p>
              <p className="contact-para">Our team will reach out soon</p>
            </Col>
          </Row>
        </Modal>
      </DemoWrap>
    </>
  );
};

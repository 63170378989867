import React, { useState, useRef } from "react";
import { TermsWrap } from "./style";
import { Col, Row } from "react-bootstrap";

const TermsAndConditions = ({ headerRef }) => {
  const [active, setActive] = useState(0);
  const privacyContentRef = useRef(null);
  const menus = [
    "Background",
    "Conclusion of Contract, Trial",
    "Scope of Software and Services",
    "Availability",
    "Customer responsibilities",
    "Grant of Rights",
    "Term and Termination",
    "Payment Term",
    "Warranty, Claims and Obligations in Case of Defects",
    "Limitation of Liability",
    "Data Protection and Confidentiality",
    "Amendments",
    "Final Provisions",
  ];
  const content = [
    [
      '1.1 Foresyt Limited, ("TymeplusHR") incorporated in Mauritius, offers a web-based HR software-as-a-service (SaaS) ("Software") for small and medium-sized companies and associated services ("Services").',
      " 1.2 These terms and conditions (“TC”) govern the subscription for the Software and the provision of Services to the customer. Software and Services may additionally be subject to offer documents (or similar) issued by TymeplusHR (together “Agreement”). Services may be subject to specific supplemental terms that apply to the customer’s subscription and those supplemental terms form part of the Agreement.",
      " 1.3 TymeplusHR expressly agrees to their application in writing.",
    ],
    [
      "2.1 The use of the Software requires creating an account (\u201CAccount\u201D). By creation of an Account, the customer submits a binding offer to conclude an Agreement for the free of charge use of the Software for trial purposes. TymeplusHR may at its sole discretion accept this offer by sending a notification to the indicated e-mail address with the login details for the Account set up. Upon Account activation, TymeplusHR grants the customer a free right to use the Software for a period of 14 days for trial purposes (\u201CTrial\u201D). The customer is only entitled to one Trial. TymeplusHR may extend the Trial at its sole discretion. After the expiration of the Trial, the customer\u2019s account will be blocked.",
      " 2.2 After expiration of the Trial according to section 2.1 the customer may conclude a fee-based Agreement with TymeplusHR for the Software and / or Services. The customer can choose between the Software plans and with a varying scope of additional apps for a predefined maximum number of employees.",
      " 2.3 Fee-based Agreements may be concluded by the customer by (a) choosing the Software and Services and adding the required contract information in their Account with TymeplusHR\u2019s confirmation, or (b) requesting a respective offer in writing or text form from TymeplusHR and the customer accepting the same",
    ],
    [
      '3.1 TymeplusHR shall provide the Software and Services during the subscription term of the Agreement. The scope of functionalities for the Software and the description of Services shall be made available to the customer on the TymeplusHR website or otherwise (e.g. via an individual offer) ("Service Description").',
      " 3.2 Software components with unrestricted scope of use (e.g. E-signature) may be subject to fair use as reasonably determined and communicated by TymeplusHR (“Fair Use Principle”). The Fair Use Principle will be exercised by TymeplusHR to ensure availability of the relevant functionality for all users. Inappropriate use or excessive use of the relevant functionality shall entitle TymeplusHR, after prior notice, to restrict customers’ use of the relevant functionality.",
      " 3.3 The Software facilitates data exchange with certain systems of third parties (“Third-Party Systems”) via interfaces (“Integrations”). The TymeplusHR website provides an overview and description of all available Integrations (“Marketplace”), whose availability to the customer may depend on the Software plan and additional apps. TymeplusHR reserves the right to make changes to the Integrations, especially in cases where these are modified or discontinued by the Third-Party System provider. Within the contractual relationship between TymeplusHR and the customer, the customer bears sole responsibility for the operation of the Third-Party System, including its availability.",
      " 3.4 The customer can switch between the offered plans of the Software and change the maximum number of employees that can be administered by a plan. TymeplusHR shall invoice any additional amounts without undue delay or as agreed between the parties. Any upgrades will take effect from the date the customer makes or confirms such adjustment. For downgrades the notice periods pursuant to sections 7.2 and 7.3 shall apply accordingly. For the avoidance of doubt, the customer is not entitled to a (pro rata) refund.",
      " 3.5 Without limiting other rights or remedies, TymeplusHR may temporarily suspend (without liability) the customer's access to any portion of the Software and Services, if (a) TymeplusHR reasonably determines that (i) there is a threat or attack on the Software or other event that may create a risk to the Software, the customer or any other third party; (ii) the customer's use of the Software disrupts or poses a security risk to the Software or any other third party; or (iii) the customer has exceeded the Fair Use Principle as set out in section 3.2; or (b) TymeplusHR has notified the customer that any amount owed by the customer under the Agreement is thirty (30) or more days overdue, and the customer has failed to submit payment in full within five (5) days of receipt of such notice (collectively, “Suspensions”). TymeplusHR shall provide notice in advance (where reasonably possible) of any Suspension and provide updates regarding resumption of Software or Services following any Suspension.",
    ],
    [
      '4.1 TymeplusHR provides the Software with an availability of 99% on an annual average. Times in which the server cannot be reached due to other technical problems beyond the control of TymeplusHR (e.g. force majeure) are excluded. Also excluded is planned maintenance work (e.g. updates to the Software) which takes place outside of Monday to Friday between 9:00 am and 6:00 pm IST ("Normal Business Hours").',
      "4.2 In case of error reports received outside the support hours, the troubleshooting begins on the following working day. Delays of the troubleshooting that the customer is responsible for (e.g. due to unavailability of a contact person on the customer side or belated notification of the disruption), are not credited towards the troubleshooting time.",
    ],
    [
      "5.1 The following responsibilities are major obligations of the customer and are not only to be classified as secondary obligations or duties.",
      "5.2 During the Trial in accordance with section 2.1, the customer is obliged to review the functionalities of the Software and to notify TymeplusHR in text form about possible defects and other deviations from the Service Description before concluding a fee-based Agreement for the use of the Software. The customer cannot invoke defects and other deviations from the Service Description, which were already known or present during the Trial, but were not reported before the conclusion of a fee-based Agreement for the use of the Software.",
      "5.3 The customer shall provide a qualified contact person together with a deputy, who is entitled to make all necessary decisions, that are required for the contractually agreed provision of Software and TymeplusHR about any changes of the contact person (including deputy) without undue delay.",
      "5.4 The customer is solely responsible for the content and data processed within the Software. The customer shall use the Software only in accordance with the Agreement and within the framework of the applicable statutory provisions and not to infringe any rights of third parties during use. The customer will inform TymeplusHR without undue delay in text form about: (i) the misuse or suspicion of misuse of the Software and Services; (ii) a risk or suspicion of a risk for the compliance of data protection or data security which occurs within the scope of the provision of the Software and Services; (iii) a risk or suspicion of a risk for the service provided by TymeplusHR, e.g. due to loss of access data or hacker attack.",
      "5.5 The customer shall ensure the following technical requirements:",
      "5.5.1 The connection to the internet in adequate bandwidth and latency is the customer’s responsibility.",
      "5.5.2 For an optimal use of the offers and functions of the Software the customer shall use the latest versions of the following browser types Google Chrome, Microsoft Edge or Mozilla Firefox or any other browser notified by TymeplusHR. Functional cookies are needed for the usability of the Software. If these are not permitted by the customer, TymeplusHR shall not be liable for any restrictions resulting from this.",
      "5.5.3 The customer is responsible for taking state-of-the-art IT security measures to ensure that the use of the Software is subject to appropriate security standards within their own organization.",
      "5.5.4 The use of shared accounts (e.g. info@client.com) is prohibited. The customer shall ensure that their users of the Software do not share their login data.",
      "5.5.5 The customer shall ensure the security of the internet connection used, in particular the use of company-owned instead of public Virtual Private Networks (VPN) as well as the use of VPN connections in public networks.",
      "5.6 The customer is responsible for the professional setup and administration of the Account. This applies regardless of whether TymeplusHR supports the customer setting up the account in any form. This includes: (i) the professional setup of the Account, in particular the migration of data, configuration of processes and products; (ii) the technical setup of Integrations in the Account and in the Third-Party System, such as determining whether certain data fields should be transferred or how customer-specific values from multiple-selection fields are to be assigned; (iii) verification of the correct functioning of the Integration on the basis of test cases (e.g. concerning the text length of open text fields) before productive use; (iv) the technical integration of interfaces on the customer side in accordance with the specification of incoming and outgoing data, including API key input and the activation of interfaces in the Third-Party System; (v) the administration of the account, in particular the creation of users and roles and the assignment of access.",
    ],
    [
      "6.1 TymeplusHR grants the customer a non-exclusive, non-transferable and time-limited usage right for the subscribed for Software for the agreed term. For rights of use to Third-Party Systems, the provisions of the respective provider will apply.",
      "6.2 The customer undertakes to use the Software only in accordance with the Agreement and not to provide it to third parties to use.",
      "6.3 TymeplusHR may process non-personal or anonymous data to develop and improve functionality and the customers’ experience with the Software. For this purpose, TymeplusHR may anonymize data stored in the Software. The customer agrees that TymeplusHR owns all rights in and is free to use any such non-personal or anonymous data in any way it deems fit for development, diagnostic, corrective, security as well as marketing or any other purposes.",
    ],
    [
      "7.1 The customer can choose a monthly or a yearly subscription Agreement. For Services, the term of the Software applies unless specifically stated otherwise.",
      "7.2 In case of Agreements with a monthly subscription, an initial term of one month shall apply. After the expiry of the initial term, the Agreement shall automatically renew on a monthly basis until either party terminates the Agreement with a notice not later than 15 days’ prior to the renewal date.",
      "7.3 In case of Agreements with an annual subscription, an initial term of one year shall apply. After the expiry of the initial term, the Agreement shall automatically renew on a yearly basis, until either party terminates the Agreement with notice not later than three months’ prior to the renewal date.",
      "7.4 The right of both parties to terminate the Agreement for good cause remains unaffected.",
      "7.5 Notice of termination must be given in written form. All licenses and rights granted under the Agreement shall be immediately terminated.",
    ],
    [
      "8.1 The customer agrees to pay the fees for the Software and any applicable Services in accordance with the applicable offer or invoice, and authorizes TymeplusHR to conduct direct debits of customer’s designated bank account or credit card for such fees as they become payable (upfront in advance for the applicable term). Electronic invoices will be sent to the customer.",
      "8.2 All amounts and fees are exclusive of taxes, duties, levies, tariffs, and other governmental charges (collectively, “Taxes”). The customer shall be responsible for payment of all Taxes and any related interest and/or penalties resulting from any payments made hereunder.",
      "8.3 For Agreements with a monthly subscription, the billing period begins on the commencement date of the Agreement or as otherwise agreed by the parties in text form and ends with the expiry of one month.",
      "8.4 For Agreements with a yearly subscription, the billing period begins on the commencement date of the Agreement or as otherwise agreed by the parties in text form and ends with the expiry of one year. Payment shall be due two weeks from the invoice date.",
    ],
    [
      "9.1 Sections 9.2, 9.4, 9.6 and 9.7 shall apply only in case of paid Software/Services provision by TymeplusHR. To the extent TymeplusHR provides Software/Services free of charge, TymeplusHR’s liability for defects is limited to fraudulent intent.",
      "9.2 TymeplusHR shall provide the Software free from material and legal defects (e.g., violation of third party intellectual property rights) and shall maintain the Software in a condition suitable for contractual use during the term of the Agreement.",
      "9.3 Any defects or disruptions of the system availability shall be reported by the customer together with the details of the circumstances of their occurrence without undue delay after it has become known. In case of occurring Software disruptions the customer will support TymeplusHR to a reasonable extent in the identification and correction of errors.",
      "9.4 TymeplusHR shall remedy the defect within a reasonable period of time. In case of reports and disruptions of the system availability which lead to a total failure of the Software and which are received within the support hours (as published by TymeplusHR), TymeplusHR will attempt to ensure a reaction time of four hours from the beginning of the disruption. In case of minor errors that do not lead to a total failure of the Software and that occur during ongoing operation, TymeplusHR will attempt to respond no later than one working day after receipt of the error message.",
      "9.5 TymeplusHR shall be entitled to show temporary workarounds and to eliminate the actual cause later by making adjustments to the Software, provided that this is reasonable for the customer.",
      "9.6 Defect claims expire within 12 months.",
    ],
    [
      "10.1 In the case of paid service provision TymeplusHR is liable according to the statutory provisions for damages resulting from the injury of life, of the body or health and for other loss due to a breach of duty resulting from intent, gross negligence and fraudulent intent. Guarantees by TymeplusHR are only given in written form and in case of doubt are to be interpreted as such, only if they are referred to as “guarantee”.",
      "10.2 In case of slight negligence for paid service provisions, TymeplusHR is only liable for damages caused by TymeplusHR and which are due to such essential breaches of duty, which endanger achieving the purpose of the Agreement or to breaches of duties, whose fulfillment enables the proper execution of the Agreement in the first place and whose compliance the customer may rely on (so-called violation of cardinal obligations). In these cases the liability of TymeplusHR is limited to typically contractual predictable damages.",
      "10.3 In the case of free service provision (e.g., within the Trial) TymeplusHR is responsible only for damage, which is based on willful intent, or gross negligence as well as fraudulent intent. This limitation of liability does not apply to damages resulting from injury to life, body or health, for this TymeplusHR is liable without limitation.",
      "10.4 The limitations of liability in sections 10.1 to 10.3 also apply to claims against executive employees, employees, other vicarious agents or subcontractors of TymeplusHR.",
    ],
    [
      "11.1 TymeplusHR acts as a processor for the customer data stored and processed in the Software and the customer shall be the data controller of such data.",
      "11.2 “Confidential Information” shall mean any information, no matter whether written or oral, which (i) by its nature is confidential or subject to secrecy or (ii) which the party, whom the information is transmitted to, must have recognized as confidential and a subject to secrecy because of exceptional circumstances. Confidential Information includes, in particular, product descriptions and specifications as well as prices. Each party agrees to the following:",
      "11.2.1 Not to disclose Confidential Information of the respective other party to third parties without express consent (at least in text form).",
      "11.2.2 To undertake to use Confidential Information only for contractually agreed purposes.",
      "11.2.3 To take at least the same precautions as they do in regards to their own Confidential Information. Such precautions shall at least be reasonable to prevent disclosure to unauthorized third parties. In addition both contracting parties are obliged to prevent the unauthorized disclosure or use of Confidential Information by their customers, employees, subcontractors or legal representatives.",
      "11.2.4 To inform each other in text form of any misuse of Confidential Information.",
      "11.3 Confidential Information shall not include information which:",
      "11.3.1 Was known to the other party prior to transmission and without an existing confidentiality agreement.",
      "11.3.2 Is transmitted by a third party not subject to a similar confidentiality agreement.",
      "11.3.3 Is otherwise publicly known.",
      "11.3.4 Was independently developed without using confidential information.",
      "11.3.5 Is released for publication in text form",
      "11.3.6	Is required to be transmitted due to a ﬁnal legally binding court order or authority provided that the party aﬀected by the transmission is informed in time in order to take legal protection actions.",
      "11.4	Neither party shall obtain Conﬁdential Information by means of reverse engineering. “Reverse  Engineering” in this context means all actions, including observing, testing, examining and reassembling, with the aim of obtaining Conﬁdential Information.",
      "11.5	The restrictions contained in sections 11.2 to 11.4 will continue to apply until the earlier of the relevant Conﬁdential Information ceasing to be conﬁdential and a period of ﬁve years following termination of the Agreement",
    ],
    [
      "12.1 TymeplusHR has the right to change these TC’s at any time or to amend regulations for the use of any newly introduced additional services or features of the Software or Services. Changes and amendments to these TC shall be announced to the customer by email to the indicated email address not later than four weeks before the scheduled changes come into force. The customer’s consent to the change of the TC will be deemed granted if the customer does not object to the amendment in text form within a period of two weeks, beginning with the day following the day of the announcement of the amendment. Any announcement shall indicate the relevant amendment, the possibility of objection, the deadline for an objection, the text form requirement and the outcome of an objection.",
      "12.2 TymeplusHR reserves the right to modify the Software and/or Services to offer deviating functionalities, unless changes or deviations are not reasonable for the customer. If significant change of the Software supported workflow of the customer and/or limitations in usability of so far generated data go along with the provision of a modified version of the Software or a change of functionality of the Software, TymeplusHR will announce this to the customer in text form at the latest four weeks before the effective date of such a change. If the customer does not object to the change in text form within a period of two weeks upon receipt of the notification of change, the change shall become part of the contract. Any announcement shall indicate the relevant amendment, the possibility of objection, the deadline for an objection, the text form requirement and the outcome of an objection.",
      "12.3 TymeplusHR further reserves the right to modify the Software and/or Services to offer deviating functionalities, (i) to the extent necessary to make the services offered by TymeplusHR compliant to the (case) law applicable to such services, in particular if the legal situation changes; (ii) to the extent TymeplusHR complies with a court order or authority decision addressed to TymeplusHR; (iii) to the extent necessary to eliminate security vulnerabilities of the software; (iv) due to significant changes in the services or contractual conditions of third-party providers or subcontracting companies, or (v) to the extent that this is predominantly beneficial for the customer. TymeplusHR especially reserves the right to restrict or discontinue the provision of additional functionalities or Integrations if the technical partners for these additional functionalities or the providers of the Third-Party Integrations significantly change or limit their services or terms of service and TymeplusHR can therefore no longer reasonably be expected to continue providing the above, such as if the additional expense due to TymeplusHR’s involvement would be economically unreasonable. For the case of annual calculation, the customer will receive an appropriate pro rata reimbursement of fees paid in advance, provided that the additional functionality or Integration was invoiced separately.",
      "12.4 TymeplusHR is entitled to adjust its list prices to compensate personnel cost or other cost increases annually in an appropriate amount. TymeplusHR will announce these price adjustments and the effective date of the price adjustments to the customer in text form. The price adjustments shall not apply to the periods the customer has already paid for. If the price increase is more than 5% of the previous price, the customer may object to this list price increase within a period of two weeks from notification. A change in the price resulting from change in the scope of features, or the number of employees to be administered shall not be deemed a price adjustment within the meaning of this section 12.4.",
      "12.5 If the customer objects to a change within the meaning of this section 12 in accordance with the relevant notification requirements, the proposed change will not be effective and the Agreement shall continue under the existing terms. In this case TymeplusHR reserves the right to terminate the Agreement extraordinarily on one month’s notice.",
      "12.6 Except as set out in sections 12.1 to 12.4, any variation to the Agreement shall be agreed by the parties in text form.",
    ],

    [
      "13.1 Unless otherwise agreed, any notification or declaration under the Agreement shall be made in writing which includes text form (e.g. email). Amendments to the Agreement shall be made in writing or text form. This shall also apply to the waiver of this form requirement.",
      "13.2 If any provision of the Agreement is invalid, illegal or unenforceable, the other provisions of the Agreement will remain enforceable and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.",
      "13.3 The Agreement between the parties shall be governed by and construed in accordance with the laws of Mauritius.",
    ],
  ];

  const marginOffset = 20; // Adjust this value as per your requirement

  const handleMenuClick = (index) => {
    setActive(index);
    const headerHeight = headerRef.current.clientHeight;
    const contentElement = document.getElementById(
      menus[index].replace(/\s+/g, "-").toLowerCase()
    );
    const contentPosition =
      contentElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: contentPosition - headerHeight - marginOffset,
      behavior: "smooth",
    });
  };

  return (
    <TermsWrap>
      <div class="heading-box">
        Terms and conditions
        <div class="circle d-none d-md-block"></div>
        <div class="circle d-none d-md-block"></div>
      </div>

      <div className="my-5">
        <Row>
          <Col md={4}>
            <div className="menus">
              <ol className={`custom-ol `}>
                {menus.map((label, index) => (
                  <a
                    href={`#${label.replace(/\s+/g, "-").toLowerCase()}`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleMenuClick(index);
                    }}
                  >
                    <li
                      className={` ${index === active && "active"}`}
                      key={index}
                    >
                      {index + 1}
                      {". "}
                      {label}
                    </li>
                  </a>
                ))}
              </ol>
            </div>
          </Col>
          <Col className="privacy-content" md={8} ref={privacyContentRef}>
            {menus.map((data, index) => (
              <div id={data.replace(/\s+/g, "-").toLowerCase()} key={index}>
                <p className="title">
                  {index + 1}
                  {". "}
                  {data}
                </p>
                <p className="content">
                  {content[index].map((list) => (
                    <li>{list}</li>
                  ))}
                </p>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </TermsWrap>
  );
};

export default TermsAndConditions;

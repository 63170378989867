import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import circle_1 from "../../Assests/Active Sphere resized.svg";
import circle_2 from "../../Assests/Inactive sphere resized.svg";
import line from "../../Assests/Line.svg";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";
import { HRWrap } from "../HRManagement/style";
import { useMediaQuery } from "@react-hook/media-query";
import DOMPurify from "dompurify";
import { useRef, useState, useEffect } from "react";

const CircleConnector = ({ steps, activeStep, setActiveStep }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [divHeight, setDivHeight] = useState(0);
  const divRef = useRef(null);
  const stepRefs = useRef([]);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (divRef.current) {
      // Get the height of the container div
      const height = divRef.current.offsetHeight;
      setDivHeight(height);
    }
  }, []);

  useEffect(() => {
    // Update the height when the active step changes
    if (divRef.current) {
      const height = divRef.current.offsetHeight / 8;
      setDivHeight(height);
    }
  }, [activeStep]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4, // Adjust the threshold as needed
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const stepIndex = parseInt(entry.target.id.replace("step-", ""));
          setActiveStep(stepIndex);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    stepRefs.current.forEach((stepRef) => {
      observer.observe(stepRef);
    });

    return () => {
      observer.disconnect();
    };
  }, [steps]);

  function createMarkup(htmlContent) {
    return { __html: DOMPurify.sanitize(htmlContent) };
  }

  return (
    <>
      <StepperWrap divHeight={divHeight}>
        <div ref={divRef}>
          {steps.map((data, index) => (
            <div
              className="step"
              key={data.id}
              id={`step-${data.id}`}
              ref={(ref) => (stepRefs.current[index] = ref)}
            >
              <div>
                <div
                  className={`circle ${
                    activeStep === index ? "active-circle" : "inactive-circle"
                  }`}
                >
                  <img
                    className={`circle-img ${
                      activeStep === index && "active-circle-img"
                    }`}
                    src={activeStep === index ? circle_1 : circle_2}
                    alt="point"
                  />
                </div>
              </div>

              <div>
                <Row className="ps-5 mobilestepper-content">
                  <Col sm={12} md={6}>
                    <div
                      className={`title ${
                        activeStep === index ? "active-title" : ""
                      }`}
                    >
                      {data.label}
                    </div>

                    <div
                      dangerouslySetInnerHTML={createMarkup(data.description)}
                      className={`caption ${
                        activeStep === index ? "active-caption" : ""
                      }`}
                    />
                    {/* <div
                     
                    >
                      {data.description}
                    </div> */}
                  </Col>
                  <Col sm={12} md={6}>
                    <img
                      src={isMobile ? data.mobimg : data.image}
                      alt="features"
                      className={`${activeStep === index ? "step-image" : ""}`}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          ))}
        </div>
      </StepperWrap>
    </>
  );
};

export default CircleConnector;

export const StepperWrap = styled.div`
  .step {
    position: relative;
    height: 100vh;
    color: gray;
    border: none !important;
    margin: 0px 0px;
  }
  .step:hover {
    background-color: transparent;
    cursor: auto !important;
  }
  .step + .step {
    margin-top: 1.5em;
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
  }
  .step.step-active {
    color: #4285f4;
  }
  .step.step-active .circle {
    background-color: #4285f4;
  }
  /* Circle */
  .circle {
    position: relative;
    width: auto;
    height: auto;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    background: #111b47;
  }

  .inactive-circle {
    position: relative;
    /* left: 10px; */
  }

  .circle:after {
    content: "";
    position: absolute;
    display: block;
    bottom: -55px;
    left: 85px;
    height: ${(props) => props.divHeight}px;
    width: 10px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-image: url(${line});
    background-size: cover;
  }

  /* .active-circle:after {
    content: "";
    position: absolute;
    display: block;
    bottom: -55px;
    left: 85px;
    height: 600px;
    width: 10px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-image: url(${line});
    background-size: cover;
  } */

  .circle-img {
    width: 180px;
    height: 150px;
    position: absolute;
    padding: 15px !important;
    z-index: 2;
    /* background-color: #1f243b;; */
  }

  /* .circle:after {
  content: ""; 
  position: absolute;
  display: block;
  bottom: -55px;
    left: 85px;
  height: 550px; 
  width: 10px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-image: url(${line}); 
  background-size: cover; 
 
}  */

  .step:last-child .circle:after {
    display: none;
  }

  .title {
    line-height: 1.5em;
    font-weight: bold;
    margin-top: 75px;
    margin-left: 40px;
    padding-bottom: 20px;
  }

  .caption {
    font-size: 0.8em;
    margin-left: 40px;
    margin-right: 90px;
    font-size: 18px;
  }

  .active-circle-img {
    /* width: 150px;
    height: 150px; */
    padding: 0 !important;
    /* opacity: 0;
    animation: slideFromBottom 1s forwards; */
  }

  @keyframes slideFromBottom {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .active-title {
    font-size: 40px;
    color: white !important;
    opacity: 0;
    animation: slideFromBottom 1s forwards;
    margin-top: 45px;
    margin-left: 40px;
    padding-bottom: 20px;
    font-weight: 600;
  }

  .active-caption {
    font-size: 18px;
    color: #e3e8fc !important;
    opacity: 0;
    animation: slideFromBottom 1s forwards;
    margin-left: 40px;
    margin-right: 90px;
  }

  .custom-para {
    padding-top: 20px;
  }

  .step-image {
    /* opacity: 0; */
    /* animation: slideFromBottom 1s forwards; */
    animation: fadeIn 5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .active-title {
      font-size: 24px;
      color: white !important;
      opacity: 0;
      animation: slideFromBottom 1s forwards;
      margin-top: 45px;
      margin-left: 0px;
    }

    .active-caption {
      font-size: 16px;
      color: #e3e8fc !important;
      opacity: 0;
      animation: slideFromBottom 1s forwards;
      margin-left: 0px;
    }

    .caption {
      margin-left: 0px;
      margin-right: 0px;
    }

    .circle-img {
      width: 100px;
    }
    .circle:after {
      left: 45px;
    }

    .step {
      padding: 0px;
      height: 150vh;
    }

    .mobilestepper-content {
      padding-right: 1rem;
    }

    .title {
      margin-left: 0px !important;
    }

    .circle:after {
      bottom: -85px;
    }
  }
`;

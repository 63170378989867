import React, { useEffect, useState } from "react";

export const UserGuide = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styleDiv = { height: windowHeight, overflowY: "hidden" };

  return (
    <div style={styleDiv}>
      <embed
        src= "https://tymeplushrusermanuallive.s3.ap-south-1.amazonaws.com/user-guide/Guide-User.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
  );
};

import React, { useState } from "react";
import { RegisterWrap } from "./style";
import { Col, Row } from "react-bootstrap";
import DetailsForm from "./DetailsForm";
import { baseURL } from "../../Services/api";
import axios from "axios";
import { Spin, message, Alert } from "antd";
import { OTP } from "./OTP/OTP";
import logo from "../../Assests/Frame 2147225291.svg";
import registerImg from "../../Assests/regsiter.svg";
import { useNavigate } from "react-router-dom";
import { SetPassowrd } from "./SetPassword";
import ConfirmationModal from "./ConfirmationModal";

const Register = () => {
  const navigate = useNavigate();

  const [registerdata, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    jobTitle: "",
    domain: "",
    employees: "",
    country: "",
    terms: false,
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [domainstatus, setDomainStatus] = useState("");
  const [domainsuccess, setDomainSuccess] = useState(false);
  const [urlloader, setUrlLoader] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [active, setActive] = useState("");
  const [token, setToken] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [show, setShow] = useState(false);

  const handleOnChange = (e) => {
    setRegisterData({ ...registerdata, [e.target.name]: e.target.value });
  };

  const handleChangeDomain = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (value.length === 0) {
      setDomainStatus("error");
      setDomainSuccess(false);
      return;
    }
    try {
      setUrlLoader(true);
      const payload = {
        domain: `${e.target.value}.tymeplushr.com`,
      };
      const response = await axios.post(
        `${baseURL}/user/check-domain`,
        payload
      );
      if (response.data.status === true) {
        setDomainStatus("success");
        setShowAlert(false);
        setDomainSuccess(true);
        setUrlLoader(false);
        setAlertMsg("");
      } else {
        setDomainStatus("error");
        setShowAlert(true);
        setAlertMsg("Domain Already Exists");
        setDomainSuccess(false);
        setUrlLoader(false);
      }
    } catch {
      setUrlLoader(false);
      setDomainSuccess(false);
    }
  };

  const handleOtpVerification = async (otp) => {
    setOtpError(false);
    if (otp.length < 6) {
      setOtpError(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`${baseURL}/user/verify-otp`, {
          email: registerdata.email,
          otp: otp,
        });
        if (response.data.status) {
          messageApi.open({
            type: "success",
            content: "OTP verified successfully",
          });
          setActive("create-password");
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
          setOtpError(false);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleResendOtpVerification = async (otp) => {
    try {
      const response = await axios.post(`${baseURL}/user/send-otp`, {
        email: registerdata.email,
      });
      if (response.data.status) {
        messageApi.open({
          type: "success",
          content: "OTP resend successfully",
        });
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCreatePassword = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/user/register`, {
        companyName: registerdata.companyName,
        country: registerdata.country,
        domain: registerdata.domain + ".tymeplushr.com",
        email: registerdata.email,
        employeeCount: registerdata.employees,
        firstName: registerdata.firstName,
        interval: "month",
        lastName: registerdata.lastName,
        monthlyCost: 0,
        password: data.password,
        paymentType: "free",
        productList: "",
        totalCost: 0,
        type: "company",
        phoneNumber: selectedCountry + registerdata.phoneNumber,
      });
      if (response.data.status) {
        setLoading(false);
        setShow(true);
        setToken(response.data.authorizationToken);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (domainsuccess) {
      setLoading(true);
      setSubmitted(false);
      try {
        const response = await axios.post(`${baseURL}/user/send-otp`, {
          email: registerdata.email,
        });
        if (response.data.status) {
          setActive("confirm-otp");
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <RegisterWrap>
      {contextHolder}
      <div>
        <p className="content-1">Ready to revolutionize your workflow?</p>
        <p className="content-2">Dive into our 14 days free trial today!</p>
      </div>
      <div>
        <Row>
          <Col className="d-flex justify-center" lg={6}>
            <img src={registerImg} alt="register" />
          </Col>
          <Col lg={6}>
            <Spin spinning={loading}>
              <div className="card p-4">
                <img
                  src={logo}
                  alt="logo"
                  width="200"
                  className="mx-auto pt-2 pb-3 cursor-pointer"
                  onClick={() => navigate("/")}
                />
                {showAlert && (
                  <Alert
                    className="alert_box"
                    message={alertMsg}
                    type="error"
                  />
                )}
                {active === "create-password" ? (
                  <SetPassowrd handleCreatePassword={handleCreatePassword} />
                ) : active === "confirm-otp" ? (
                  <OTP
                    handleOtpVerification={handleOtpVerification}
                    setActive={setActive}
                    handleResendOtpVerification={handleResendOtpVerification}
                    otpError={otpError}
                    setOtpError={setOtpError}
                  />
                ) : (
                  <DetailsForm
                    handleChangeDomain={handleChangeDomain}
                    registerdata={registerdata}
                    domainstatus={domainstatus}
                    setDomainStatus= {setDomainStatus}
                    setDomainSuccess = {setDomainSuccess}
                    handleOnChange={handleOnChange}
                    onSubmit={onSubmit}
                    showAlert={showAlert}
                    alertMsg={alertMsg}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    setActive={setActive}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    setRegisterData = {setRegisterData}
                  />
                )}
              </div>
            </Spin>
          </Col>
        </Row>
      </div>

      <ConfirmationModal token={token} show={show} setShow={setShow} />
    </RegisterWrap>
  );
};

export default Register;

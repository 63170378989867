import styled from "styled-components";

export const FooterWrap = styled.div`
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #1f243b; */
    color: #e3e8fc;
  }

  .footer-text {
    display: flex;
    justify-content: space-between;
    color: #e3e8fc;
    font-size: 18px;
    font-weight: 400;
  }

  .break{
    color: white;
    margin-bottom: 20px;
  }

  .footer-link {
    font-size: 16px !important;
    font-weight: 400;
  }

  .footer-link :hover {
    color: #2d53da;
  }

  .link-style:hover {
    color: #2d53da;
  }


  @media screen and (max-width: 768px) {
    .footer-text {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .footer-link {
      // font-size: 24px !important;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 25px;
    }

    .justify-content-center-mobile {
      justify-content: center !important;
      column-gap: 10px !important;
      font-size: 40px;
    }

    .socialmedia {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }

    .footer-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
    }

    .text1 {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 2rem !important;
    }

    .text2 {
      font-size: 14px;
      font-weight: 400;
    }

    .img-footer {
      padding-bottom: 0rem !important;
    }
  }
`;

import Styled from "styled-components";

const FeedbackWrap = Styled.div`
.text1{
    font-size: 42px;
    font-weight:700;
    padding-right:30%;
    line-height: 160%;
}

.text2{
    font-size: 18px;
    font-weight: 400;
    padding-right:5%
}

.bgimg{
    border-radius:8px !important;
}
.content{
    text-align: center !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    font-family: "DM Sans", sans-serif !important;
    color: #1F243B;
    
}

.form-control{
   border: var(--bs-border-width) solid #95a2ae91;
}

.placeholder-style::placeholder {
  color: #1f243b;
  font-size: 16px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif !important;
}

.text3{
  font-size:14px;
  font-weight: 400;
}

.feedback-section{
  margin-top:5%;
  margin-bottom:5%;
}

.text-area{
  line-height: 4.5 !important;
}

.error-msg {
    color: #f2545b;
    font-size: 14px;
    font-family: "DM Sans", sans-serif !important;
  }


@media only screen and (max-width: 768px) {
  .feedback-form{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .text1{
    font-size: 20px;
    font-weight:700;
    padding-right:0% !important;
    
}

.text2{
    font-size: 18px;
    font-weight:400;
    
}

.contain{
  text-align:center !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.sub-btn{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.feedback-contsection{
  margin-inline-start: 0rem;
  margin-left: 0rem !important;
}

.successtick {
    position: absolute !important;
    top: 78px !important;
}

.content{
   text-align: center !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #1F243B;
    font-family: "DM Sans", sans-serif !important;
    
}

}

`;

export default FeedbackWrap;

import { motion } from "framer-motion";
import { debounce } from 'lodash';
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import appraisal from "../../Assests/Appraisal.svg";
import band from "../../Assests/Band.svg";
import documentimg from "../../Assests/Documents.svg";
import wtemplate from "../../Assests/Formtemplate.svg";
import hrapprisal from "../../Assests/HRappraisal.svg";
import hrband from "../../Assests/HRband.svg";
import hrdocuments from "../../Assests/HRdocuments.svg";
import hrforms from "../../Assests/HRforms.svg";
import hrpeople from "../../Assests/HRpeople.svg";
import hrpolicies from "../../Assests/HRpolicies.svg";
import hrrecruitment from "../../Assests/HRrecruitement.svg";
import people from "../../Assests/People..svg";
import policies from "../../Assests/Policies.svg";
import recruitment from "../../Assests/Recruitment.svg";
import wpeople from "../../Assests/WPeople.svg";
import wappraisal from "../../Assests/Wappraisal.svg";
import wdocument from "../../Assests/Wdocuments.svg";
import wpolicies from "../../Assests/Wpolicies.svg";
import wrecruiment from "../../Assests/Wrecruitment.svg";
import gband from "../../Assests/gband.svg";
import template from "../../Assests/gformtemplate.svg";
import { HRWrap } from "./style";

const menus = [
  {
    menu: "People",
    title: "Manage People",
    description: `We provide you a centralized database where you can store all employee information securely. From basic contact details to employment history, qualifications, and performance evaluations, everything you need to know about your team members is easily accessible in one place. Our intuitive interface makes it simple to keep all employee records organized and up-to-date.`,
    image: hrpeople,
    activeicon: wpeople,
    inactiveicon: people,
  },
  {
    menu: "Recruitment",
    title: "Hire & Onboard",
    description:
      "Finding and hiring top talent has never been easier. Our comprehensive recruitment solution simplifies the entire hiring process, from creating job posts and posting them on multiple job portals to managing candidates and onboarding the best talent seamlessly.",
    image: hrrecruitment,
    activeicon: wrecruiment,
    inactiveicon: recruitment,
  },
  {
    menu: "Appraisal",
    title: "Foster Employee Development",
    description: `You can conduct performance appraisals and provide feedback to employees. Set goals, track progress, and facilitate constructive conversations to support employee development and drive organizational success.`,
    image: hrapprisal,
    activeicon: wappraisal,
    inactiveicon: appraisal,
  },
  {
    menu: "Policies",
    title: "Centralize Company Policies",
    description: `Keep all company policies organized and accessible in one convenient location with TymeplusHR Policies. From employee handbooks to HR guidelines and compliance documents, our platform ensures that everyone in the organization has easy access to the information they need to stay informed and compliant.`,
    image: hrpolicies,
    activeicon: wpolicies,
    inactiveicon: policies,
  },
  {
    menu: "Form Template",
    title: "Customize Your Forms",
    description: `Form Template helps you customize templates and create new forms to streamline across the organization. Whether it's collecting employee feedback, processing leave requests, or conducting performance reviews, our form template empowers you to create tailored solutions that meet their specific needs.`,
    image: hrforms,
    activeicon: wtemplate,
    inactiveicon: template,
  },
  {
    menu: "Documents",
    title: "Secure Document Storage",
    description: `Store and manage employee documents securely with TymeplusHR Documents. From contracts and certifications to performance reviews and training materials, our platform ensures that all employee documents are stored safely and accessible when needed.`,
    image: hrdocuments,
    activeicon: wdocument,
    inactiveicon: documentimg,
  },
  {
    menu: "Band",
    title: "Define Salary Bands",
    description: `Define salary bands based on years of experience and other criteria using TymeplusHR Band Range. Ensure fair and transparent compensation practices within your organization by establishing clear guidelines for salary ranges, allowing HR teams to make informed decisions when hiring and promoting employees.`,
    image: hrband,
    activeicon: band,
    inactiveicon: gband,
  },
];

const HRManagementComponent = ({ isHRManagementVisible, setScrollItem }) => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(
    menus[selectedMenuIndex] || {}
  );

  useEffect(() => {
    setSelectedMenu(menus[selectedMenuIndex] || {});
  }, [selectedMenuIndex]);


  // const handleScroll =debounce( () => {
  //   const nextIndex = Math.min(selectedMenuIndex + 1, menus.length - 1);
  //   setSelectedMenuIndex(nextIndex);
  // }, 300);


  // useEffect(() => {
  //   if (isHRManagementVisible) {
  //     setScrollItem("Features")
  //     const handleScrollEvent = (event) => {
  //       if (menus[selectedMenuIndex].menu === "Band") {
  //         handleScroll();
  //       } else {
  //         event.preventDefault();
  //         handleScroll();
  //       }

  //     };

  //     window.addEventListener("wheel", handleScrollEvent, { passive: false });

  //     return () => {
  //       window.removeEventListener("wheel", handleScrollEvent);
  //     };
  //   }
  // }, [selectedMenuIndex, isHRManagementVisible]);

  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [selectedMenuIndex, isHRManagementVisible]);
  // const handleKeyDown = (event) => {
  //    if (event.key === 'ArrowDown') {
  //     handleScroll();
  //   }
  // };
 
  return (
    <>
      <HRWrap>
        <Container className="py-5 my-4 management d-none d-lg-block">
        
          <h3 className="pb-2 d-flex justify-content-center text-align-center color5 text1">
            HR Management
          </h3>
          <Row>
            <Col lg={5}>
              <Container className="ms-4 pt-2 menus-contain">
                {menus.map((menu, index) => (
                  <div
                    key={index}
                    className={`menu-item cursor-pointer py-3 rounded-lg d-flex px-4 justify-content-start align-items-center text3 color2 ${
                      selectedMenuIndex === index ? "active" : ""
                    }`}
                    onClick={() => setSelectedMenuIndex(index)}
                    style={{
                      fontSize: selectedMenuIndex === index ? "22px" : "18px",
                    }}
                  >
                    <img
                      src={
                        selectedMenuIndex === index
                          ? menu.activeicon
                          : menu.inactiveicon
                      }
                      alt="image"
                      className="pe-1"
                    />
                    {menu.menu}
                  </div>
                ))}
              </Container>
            </Col>

            <Col lg={7}>
              <Container>
                <motion.h3 className="py-3 color3 text2">
                  {selectedMenu.title}
                </motion.h3>
                <motion.p className="color2 text3">
                  {selectedMenu.description}
                </motion.p>
                <div className="hr-img">
                <img
                  src={selectedMenu.image}
                  alt="image"
                  width="100%"
                  className="py-2"
                />
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </HRWrap>
      
    </>
  );
};

export default HRManagementComponent;

import styled from "styled-components";

export const HomerWrap = styled.div`
  .para {
    font-weight: 600;
    color: #111b47;
    font-size: 56px;
  }

  .organi {
    color: #2d53da;
    font-weight: 700;
  }

  .text1{
    font-size: 18px;
    font-weight: 400;
  }

  .text2{
    /* padding-left: 150px; */
  }

  .img-contain{
    text-align: end;
  }

  @media screen and (max-width: 768px) {
   
    // .theme-filldbtn{
    //   padding: 5px 13px !important;
    // }
    // .theme-outlinedbtn{
    //   padding: 5px 12px !important;
    // }
    .img-contain{
      padding-top: 50px;
    }

    .text2{
    padding-left: 0px !important;
    text-align: center !important;
  }

  .para {
    font-size: 30px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    }

    .text1{
    font-size: 16px !important;
    font-weight: 400;
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center !important;
  }

  .img-contain {
    padding-top: 0px !important;
}

.container{
  padding-top: 0rem !important;
  margin-top: 0rem !important;
}


.para {
  padding-top: 0rem !important;
}
  }
`;

import Styled from "styled-components";

const ContactWrap = Styled.div`
.bg-color{
    background-color: #1B3283;
}

.contact-style{
    color:white;
    font-size: 18px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif !important;
}

.contact{
    color: #FFFFFF !important;
    font-size: 50px;
    font-weight: 700;
}

.text-content{
  color:white;
    font-size: 18px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif !important;
}

.contact-contain{
   padding-left: 7.5rem;
}

.form-control{
   border: var(--bs-border-width) solid #95a2ae91;
}

.ant-picker-outlined{
  width: 100% !important;
}

.placeholder-style::placeholder {
  color: #1f243b;
  font-size: 16px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif !important;
}

.anticon{
  color: #616161 !important;
} 

.ant-picker-input ::placeholder {
  color: #616161 !important; /* Change this to the desired placeholder color */
}


.error-msg {
    color: #f2545b;
    font-size: 14px;
    font-family: "DM Sans", sans-serif !important;
  }
  
@media screen and (max-width: 768px) {

    .img-cont{
      position: relative;
    top: 40px;
    text-align: center;
    justify-content: center;
    display: flex;


    }
    
    .image-col{
      margin-left: 0rem !important;
      margin-inline-start: 0rem;
    }
    
    .contact-contain{
   padding-left: 0px !important;
   text-align: center;
   padding-bottom: 0rem !important;
   margin-bottom: 0rem !important;
}

.contact{

    font-size: 24px;
    font-weight: 700;
}

  .contact-form{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .text-content{
    padding-bottom: 0rem !important;
  }

  p{
    color: #1F243B;
    font-size: 16px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif !important;
}

.successtick {
    position: absolute !important;
    top: 78px !important;
}

/* .ant-picker-outlined{
  width: 330px !important;
} */
  
}


/* @media screen and (max-width: 350px) {
  .ant-picker-outlined{
  width: 270px !important;
}
} */


`;

export default ContactWrap;

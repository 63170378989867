import React from "react";
import { Headers } from "../Header";
import { Footers } from "../Footer";
import { Outlet } from "react-router-dom";

export const Main = ({color, scrollItem, headerRef}) => {
  return (
    <>
      <Headers headerRef={headerRef} color={color} scrollItem={scrollItem} />
      <Outlet />
      <Footers />
    </>
  );
};

import styled from "styled-components";

export const TermsWrap = styled.div`
  padding: 5%;

  .heading-box {
    font-size: 40px;
    padding: 50px 30px;
    background-image: linear-gradient(45deg, #2d51d6, #232e59);
    color: white;
    font-weight: 500;
    text-align: center;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
  }
  .circle {
    display: block;
    background: black;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 70px #2d51d6;
  }

  .circle:nth-child(1) {
    left: -9px;
    top: 30px;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle at 50px 40px, #4765da, #ffffff);
  }

  .privacy-heading{
    font-size: 29px;
    margin-top: 10px;
    font-weight: 700;
  }

  .sub-heading{
    font-size: 17px;
  }

  .privacy-subheading{
    font-size: 25px;
    margin-top: 10px;
    font-weight: 700;
  }

  .circle:nth-child(2) {
    right: 0;
    top: 150px;
    width: 150px;
    height: 150px;
    background: radial-gradient(circle at 50px 70px, #2d51d6, #ffffffa1);
  }

  .menus{
    border-radius: 20px;
    box-shadow:  0 0 10px rgba(0, 0, 0, 0.30);
    padding: 10px;
  }

  .custom-ol li {
    padding: 15px;
    border-radius: 15px;
    margin: 5px;
  }

  .custom-ol li:hover, .active {
   color: #2d51d6;
   background: #2d51d636;
  }

  .title{
    font-size: 25px;
    padding: 20px;
  }

  .content{
    font-size: 15px !important;
    color: #0000009c;
    word-spacing: 1px;
    text-align: justify !important;
    padding: 10px;
  }
  

  // .privacy-content{
  //   height: 400px;
  //   overflow: auto;
  // }
  
  // .privacy-content::-webkit-scrollbar {
  //   width: 0;  /* Set width to 0 to hide scrollbar */
  //   background: transparent; /* Optional: Set background color */
  // }
`;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { OTPWrap } from "./style";

export const OTP = ({
  handleOtpVerification,
  email,
  setActive,
  handleResendOtpVerification,
  otpError,
  setOtpError,
}) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
      ].includes(event.key);
    if (!isNumericKey) {
      event.preventDefault();
    }
  };

  return (
    <>
      <OTPWrap>
        <div className="mb-3">
          <div className="otp-title">Verify your account!</div>
          <div className="otp-subtitle my-2">
            Check your email for OTP and enter it here to verify your account
          </div>
        </div>
        <div>
          <div className="pt-4 text2 ms-2 color2">Enter OTP</div>
          <div className="otp-center mt-1">
            <OtpInput
              value={otp}
              onChange={(newOtp) => {
                setOtp(newOtp);
                if (newOtp.length < 1) {
                  setOtpError(true);
                } else {
                  setOtpError(false);
                }
              }}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} className=" py-2" />}
            />
          </div>
          {otpError && (
            <p className="error-msg ms-2 pt-1">This Field is required</p>
          )}
          <div
            className="color5 text2 cursor-pointer ms-2 d-flex pt-1"
            onClick={() => {
              handleResendOtpVerification(otp, setOtp);
            }}
          >
            Resend OTP
          </div>
          <div className="text-center pt-4 mt-4 pb-2">
            <div className=" mb-4 d-flex justify-content-center">
              <button
                className="theme-filldbtn"
                onClick={() => {
                  handleOtpVerification(otp);
                }}
              >
                Continue
              </button>{" "}
            </div>
          </div>
        </div>
      </OTPWrap>
    </>
  );
};

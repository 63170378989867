import styled from "styled-components";
import loginbg from "../../../../Assests/Signupbackground.svg";

export const OTPWrap = styled.div`
  .bgimage {
    background-image: url(${loginbg});
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    background-size: cover;
  }

  .text1 {
    font-size: 14px;
    font-weight: 500;
  }

  .text2 {
    font-size: 16px;
    font-weight: 500;
  }

  .text3 {
    font-size: 28px;
    font-weight: 700;
  }

  .text4 {
    font-size: 48px;
    font-weight: 700;
  }

  .text5 {
    font-size: 20px;
    font-weight: 400;
  }

  .contain {
    border: 6px solid #ffffff;
  }

  .text-content {
    padding-top: 25%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 20%;
  }

  .resend-otp {
    padding-left: 18.5rem;
    padding-bottom: 3rem;
  }

  .otpmodal-contain {
    /* margin-left: 10%;
   margin-right: 15%;
   padding-top: 2%; */
  }

  .otp-outlinedbtn {
    color: #2d53da;
    border: 1px solid #2d53da;
    background-color: transparent;
    border-radius: 10px;
    padding: 5px 20px;
  }

  .verifyotp-outlinedbtn {
    color: #2d53da;
    border: 1px solid #2d53da;
    background-color: transparent;
    border-radius: 10px;
    padding: 5px 35px;
  }

  input {
    border: 1px solid #95a2ae;
  }

  .all-contain {
    height: 85vh !important;
  }

  .enter-otp {
    padding-left: 3rem;
  }

  .forgot-enter-otp {
    padding-left: 1rem;
  }

  @media screen and (max-width: 768px) {
    .resend-otp {
      padding-left: 16.2rem;
      padding-bottom: 1rem;
    }

    .enter-otp {
      padding-left: 4rem;
    }

    .forgot-enter-otp {
      padding-left: 3.5rem;
    }

    .text4 {
      font-size: 27px;
      font-weight: 700;
    }

    input {
      margin: 0 5px !important;
      width: 1.6rem !important;
    }

    .text3 {
      font-size: 22px !important;
      font-weight: 700;
    }

    .text5 {
      font-size: 17px !important;
      font-weight: 400;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }

    .mobileview-form {
      height: 100vh;
    }

    .otp-view {
      display: flex;
      text-align: center;
      justify-content: center;
    }

    .text1 {
      padding-left: 57px !important;
    }
  }

  input {
    border: 1px solid #95a2ae;
    border-radius: 0.25em;
    margin: 0 10px;
    width: 100% !important;
    height: 50px;
    &:focus-within,
    &:focus {
      outline: 1px solid #d9d9d9;
    }

    .pb-5 {
      padding-bottom: 6rem !important;
    }

    .resendform {
      padding-top: 0rem !important;
    }
    .resendform {
      margin-top: 0rem !important;
    }

    .remindaccount {
      padding-bottom: 5rem !important;
    }
    .resend-signup {
      padding-bottom: 2.5rem !important;
    }

    .bgimage {
      background-color: #ffffff !important;
    }
  }
`;

import { useMediaQuery } from "@react-hook/media-query";
import React, { useEffect, useRef, useState } from "react";
import { Features } from "../Features";
import HRManagement from "../HRManagement";
import { HRmanage } from "../HRManagement/HrMobilemanage";
import { HRWrap } from "../HRManagement/style";
import { HowtoUse } from "../HowtoUse";
import { Scrollwrapper } from "../Scrollwrapper";
import Tymeplusmobile from "../TymepluMobile";
import { Home } from "./Home";
import HowtouseWrap from "../HowtoUse/style";
import Contactbanner from "../ContactBanner";
import { Feedback } from "../Feedback";

export const Homepage = ({ setColor, color, setScrollItem }) => {
  const hrManagementRef = useRef(null);
  const featureRef = useRef(null);
  const homeRef = useRef(null);
  const [isHRManagementVisible, setIsHRManagementVisible] = useState(false);
  const [isFeatureVisible, setIsFeatureVisible] = useState(false);
  const [isHomeVisible, setIsHomeVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  useEffect(() => {
    const handleScroll = () => {
      const hrElement = hrManagementRef.current;
      const featureElement = featureRef.current;
      const homeElement = homeRef.current;

      if (hrElement && featureElement && homeElement) {
        const { top: hrTop, bottom: hrBottom } =
          hrElement.getBoundingClientRect();
        const { top: featureTop, bottom: featureBottom } =
          featureElement.getBoundingClientRect();
        const { top: homeTop, bottom: homeBottom } =
          homeElement.getBoundingClientRect();
        const halfScreenHeight = window.innerHeight / 2;
        const isFeatureVisible = featureTop < 200 && featureBottom >= 140;
        const isFeatureFadeIn = featureTop < 1000;
        setFadeIn(isFeatureFadeIn);

        const isHRManagementVisible =
          (hrTop < halfScreenHeight && hrBottom >= 160) ||
          (hrBottom > halfScreenHeight && hrTop <= halfScreenHeight);

        const isHomeVisible = homeTop >= -800 && homeBottom < -100;
        setIsHomeVisible(isHomeVisible);
        setIsFeatureVisible(isFeatureVisible);
        if (isFeatureVisible) {
          setIsHRManagementVisible(false);
        } else {
          setIsHRManagementVisible(isHRManagementVisible);
        }
        if (isFeatureVisible) {
          setColor(true);
          setScrollItem("Features");
        } else {
          setColor(false);
        }
        if (featureTop > 200) {
          setScrollItem("");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const featureStyles = {
    position: isFeatureVisible || isHRManagementVisible ? "sticky" : "static",
    top:
      isFeatureVisible || isHRManagementVisible
        ? isMobile
          ? "50px"
          : "70px"
        : "auto",
    zIndex: isFeatureVisible || isHRManagementVisible ? "1000" : "auto",
    backgroundColor: isHRManagementVisible ? "white" : "#1f243b",
  };

  return (
    <div>
      <div ref={homeRef}>
        <Home setColor={setColor} />
      </div>

      <div style={featureStyles} className="py-4 my-0" id="Features">
        <HRWrap>
          <h3
            className="text4 underline contain-margin"
            style={{ color: isHRManagementVisible ? "#1F243B" : "white" }}
          >
            <span className="underline-text">Feat</span>ures
          </h3>
        </HRWrap>
      </div>
      <div ref={featureRef}>
        <Features
          setColor={setColor}
          isFeatureVisible={isFeatureVisible}
          isHRManagementVisible={isHRManagementVisible}
          fadeIn={fadeIn}
          setFadeIn={setFadeIn}
        />
      </div>
      <div ref={hrManagementRef}>
        <HRManagement
          isHRManagementVisible={isHRManagementVisible}
          setScrollItem={setScrollItem}
        />
      </div>
      <HRmanage />
      <HowtoUse setScrollItem={setScrollItem} />
      <Tymeplusmobile />
      <HowtouseWrap>
        <Contactbanner />
        <section id="Feedback">
          <div className="mt-4 pt-4">
            <Feedback />
          </div>
        </section>
      </HowtouseWrap>
      <Scrollwrapper />
    </div>
  );
};

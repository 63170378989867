import Styled from "styled-components";

const HeaderWrap = Styled.div`
 .text-color{
    color: #1F243B !important;
 }
 .login-btn{
  width: 150px;
 }

 .login-btn:hover {
    color: #1F243B !important; /* Change text color on hover */
    background: #E3E8FC !important; /* Change border color on hover */
}

.login-button{
   color: #1F243B !important;
   text-decoration: none !important;
   background: transparent;
   border: none;
}

 .btn-color{
    background-color: #1F243B !important;
    border-color: #1F243B !important;
    width: 150px;
 }

 .btn-color:hover{
  background-color: #2d53da !important;
  border-color: #2d53da !important;
 }

 .close-btn{
   margin-left: auto !important; 
 }

 .menus{
      font-family: DM Sans, sans-serif !important;
      font-style: normal;
   }

   /* .navbar-toggler-icon,
  .navbar-toggler {
    color: #fff !important;
    background-color: #fff !important;
  }

  .dropdown-toggle::after {
    color: #fff !important;
  } */


  .btnsignup-color{
    background-color:white;
    color: #1f243b;
    border: none;
  }

  .btnsignup-color:hover{
    background-color: #6385FF !important;
  border-color: #6385FF !important;
  color: white;
  }

  .bg-btncolor{
    background-color: #1F243B !important;
    border-color: #1F243B !important;
  }

  .login-hedaerbtn{
  display: flex;
  justify-content: end !important;
  width:100%
}

.Navbar-contain{
  display: flex;
  justify-content: center;
  column-gap: 25px;
}

.login-btnalign{
  display: flex;
  justify-content:center;
}

.login-mobilebtnalign{
  display: block;
  justify-content:center;
}



 @media screen and (max-width: 768px) {

   .menus{
      padding-top: 20px !important;
      font-size:24px;
      font-weight: 400;
      font-family: "DM Sans", sans-serif !important;
      font-style: normal;
   }

   .login-btn{
    width: 144px;
   font-size: 16px;
   font-weight: 500;
   margin-right: 0px !important;
 }

 .btn-color{
    width: 144px;
   
 }

 .login-mobilebtn{
  padding-top: 30%;
  display: inline-grid;
    justify-content: center;
}

 .menus-contain{
   position: relative;
    display: flex;
    justify-content: center !important;
    padding-inline-end: 0rem !important;
    padding-right:0rem !important ;
  }

 }

`;

export default HeaderWrap;

import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { RegisterWrap } from "../style";
import logo from "../../../Assests/Frame 2147225291.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../Services/api";
import { Spin, message } from "antd";
import OtpInput from "react-otp-input";
import { OTPWrap } from "../Register/OTP/style";
import { CreatePassword } from "./createpassword";

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [active, setActive] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!email.trim()) {
      setEmailError(true);
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(`${baseURL}/user/reset-otp`, {
        email: email,
      });
      if (response.data.status) {
        setActive("otp");
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOtpVerification = async (otp) => {
    setOtpError(false);
    if (otp.length < 6) {
      setOtpError(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`${baseURL}/user/verify-otp`, {
          email: email,
          otp: otp,
        });
        if (response.data.status) {
          messageApi.open({
            type: "success",
            content: "OTP verified successfully",
          });
          setActive("create-password");
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
          setOtpError(false);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleCreatePassword = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/user/reset-password`, {
        email: email,
        password: password,
      });
      if (response.data.status) {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
        navigate("/login");
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Tab",
      ].includes(event.key);
    if (!isNumericKey) {
      event.preventDefault();
    }
  };

  const validateEmail = (value) => {
    const isValidEmail = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      value
    );
    setEmailError(!isValidEmail);
  };

  return (
    <>
      <RegisterWrap>
        {contextHolder}
        <div className="min-h-screen bgimage">
          <div className="passwordform-contain">
            <Row className="justify-content-center ">
              <Col md={12}>
                <div className="rounded-xl shadow-lg overflow-hidden contain ">
                  <Row>
                    <Col
                      className="text4 color4 d-none d-lg-block"
                      xs={12}
                      md={12}
                      lg={7}
                    >
                      <div className="text-start text-content">
                        <h1 className="pb-5">Welcome Back!</h1>{" "}
                        <p className="">
                          "Let's Dive Into Efficiency Together"
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="pb-5 pt-4 d-flex flex-column bg-white mobileview-form"
                      xs={12}
                      md={12}
                      lg={5}
                    >
                      <img
                        src={logo}
                        alt="logo"
                        width="150"
                        className="mx-auto pt-2 cursor-pointer"
                        onClick={() => navigate("/")}
                      />

                      <h1 className="text-center text-bold text3 color3 py-3">
                        Forgot Password
                      </h1>
                      {active == "create-password" ? (
                        <p className="text-center color2 text5 px-4 pb-3">
                          Enter and confirm your password to <br /> proceed
                          further
                        </p>
                      ) : active == "otp" ? (
                        <p className="text-center color2 text5 pt-3 pb-4 px-4">
                          Enter the OTP sent to your registered mail, to change
                          the password
                        </p>
                      ) : (
                        <p className="text-center color2 text5 px-4 pt-3 pb-4">
                          Enter registered email ID to change your password
                        </p>
                      )}

                      {active === "create-password" ? (
                        <Spin spinning={loading}>
                          <CreatePassword
                            handleCreatePassword={handleCreatePassword}
                            password={password}
                            setPassword={setPassword}
                            confirmPassword={confirmPassword}
                            setConfirmPassword={setConfirmPassword}
                          />
                        </Spin>
                      ) : active === "otp" ? (
                        <OTPWrap>
                          <Spin spinning={loading}>
                            <div>
                              <div className="pt-4 text2 ms-2 color2">
                                Enter OTP
                              </div>
                              <div className="otp-center mt-1">
                                <OtpInput
                                  value={otp}
                                  onChange={(newOtp) => {
                                    setOtp(newOtp);
                                    if (newOtp.length < 1) {
                                      setOtpError(true);
                                    } else {
                                      setOtpError(false);
                                    }
                                  }}
                                  numInputs={6}
                                  renderSeparator={<span></span>}
                                  renderInput={(props) => (
                                    <input {...props} className=" py-2" />
                                  )}
                                />
                              </div>
                              {otpError && (
                                <p className="error-msg ms-2 pt-1">
                                  This Field is required
                                </p>
                              )}

                              <div className="text-center pt-4 mt-4 pb-2">
                                <div className=" mb-4 d-flex justify-content-center">
                                  <button
                                    className="theme-filldbtn"
                                    onClick={() => {
                                      handleOtpVerification(otp);
                                    }}
                                  >
                                    Continue
                                  </button>{" "}
                                </div>
                              </div>
                            </div>
                          </Spin>
                        </OTPWrap>
                      ) : (
                        <Spin spinning={loading}>
                          <Form className="px-4">
                            <Form.Group
                              controlId="formBasicEmail"
                              className="py-3 text-start"
                            >
                              <Form.Label className="text1 color2">
                                Email ID
                              </Form.Label>
                              <Form.Control
                                name="email"
                                type="email"
                                placeholder=""
                                className="placeholder-style"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  validateEmail(e.target.value);
                                }}
                                isInvalid={emailError}
                              />
                              <Form.Control.Feedback type="invalid">
                                {!email && <span>This field is required.</span>}
                                {email && emailError && (
                                  <span>Please enter a valid email.</span>
                                )}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-center pt-4 mt-2 pb-2">
                              <button
                                className="form-btn"
                                onClick={onSubmit}
                                disabled={emailError}
                              >
                                Continue
                              </button>
                            </div>
                          </Form>
                        </Spin>
                      )}
                      <div className="text-center pb-1 text2">
                        <p>
                          New User?
                          <span
                            className="color5 cursor-pointer ps-1"
                            onClick={() => navigate("/register")}
                          >
                            Start a free trial
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </RegisterWrap>
    </>
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters at minimum"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Please enter same Password"),
});

export const SetPassowrd = ({ handleCreatePassword }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PasswordSchema),
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (fieldName) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  return (
    <>
      <div className="mb-3">
        <div className="otp-title">Set Password!</div>
        <div className="otp-subtitle my-2">
          Enter and confirm password to proceed further
        </div>
      </div>
      <Form
        className=" pb-4 confirm-form"
        onSubmit={handleSubmit(handleCreatePassword)}
      >
        <Form.Group controlId="forgotPassword" className="pb-2">
          <Form.Label type="password" className="text1 color2">
            Password
          </Form.Label>
          <InputGroup>
            <Form.Control
              {...register("password")}
              name="password"
              type={showPassword.password ? "text" : "password"}
              placeholder=""
            />

            <InputGroup.Text
              className="cursor-pointer"
              onClick={() => togglePasswordVisibility("password")}
            >
              {showPassword.password ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </InputGroup.Text>
          </InputGroup>

          <p className="error-msg pt-2">{errors.password?.message}</p>
        </Form.Group>
        <Form.Group controlId="confirmPassword" className="pb-2">
          <Form.Label type="password" className="text1 color2">
            Confirm Password
          </Form.Label>

          <InputGroup>
            <Form.Control
              {...register("confirmPassword")}
              name="confirmPassword"
              type={showPassword.confirmPassword ? "text" : "password"}
              placeholder=""
            />
            <InputGroup.Text
              className="cursor-pointer"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            >
              {showPassword.confirmPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </InputGroup.Text>
          </InputGroup>

          <p className="error-msg pt-2">{errors.confirmPassword?.message}</p>
        </Form.Group>
        <div className="text-center pt-4 pb-2">
          <button type="submit" className="theme-filldbtn">
            Continue
          </button>
        </div>
      </Form>
    </>
  );
};

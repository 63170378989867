import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { OTPWrap } from "./style";

export const OTPModal = ({
  handleOtpVerification,
  email,
  setActive,
  handleResendOtpVerification,
  otpError,
  setOtpError
}) => {
  const [otp, setOtp] = useState("");

  return (
    <>
      <OTPWrap>
        <div>
          <div className="mt-4 pt-4 text2 enter-otp color2">Enter OTP</div>
          <div className="otp-center mt-1">
            <OtpInput
              value={otp}
              onChange={(newOtp) => {
                setOtp(newOtp);
                if (newOtp.length < 1) {
                  setOtpError(true);
                } else {
                  setOtpError(false);
                }
              }}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => (
                <input {...props} className=" py-2"/>
              )}
            />
          </div>
          {otpError && (
            <p className="error-msg enter-otp pt-1">This Field is required</p>
          )}
          <div
            className="color5 text2 cursor-pointer resend-otp d-flex pt-1"
            onClick={() => {
              handleResendOtpVerification(otp, setOtp);
            }}
          >
            Resend OTP
          </div>
          <div className="text-center pt-4 mt-4 pb-2">
            <div className=" mb-4 d-flex justify-content-center">
              <button
                className="otp-outlinedbtn me-3"
                onClick={() => setActive("next")}
              >
                Previous
              </button>{" "}
              <button
                className="verifyotp-outlinedbtn"
                onClick={() => {
                  handleOtpVerification(otp);
                }}
              >
                Verify
              </button>{" "}
            </div>
          </div>
        </div>
      </OTPWrap>
    </>
  );
};

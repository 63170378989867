import styled from "styled-components";

export const RegisterWrap = styled.div`

padding: 5% 13%;

@media screen and (max-width: 600px) {
  
padding: 5%;
}

.content-1{
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}

.content-2{
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #2d51d6;
  padding: 20px;
}

.card{
  box-shadow:  0 0 10px rgba(0, 0, 0, 0.30);
  padding: 20px;
  border-radius: 15px;

}

.link{
  color: #2d51d6;
  text-decoration: underline;
}

.error-msg {
  color: #f2545b;
  font-size: 14px;
  font-family: "DM Sans", sans-serif !important;
}


.otp-title{
  font-size: 26px;
  text-align: center;
  font-weight: 500;
}

.otp-subtitle{
  font-size: 18px;
  text-align: center;
  font-weight: 300;
}




`;

import styled from "styled-components";

export const Globalstyle = styled.div`
  .color1 {
    color: #1f243b;
  }
  .color2 {
    color: #636980;
  }
  .color3 {
    color: #002862;
  }
  .color4 {
    color: #ffffff;
  }
  .color5 {
    color: #2d53da;
  }

  .color6 {
    color: #e3e8fc;
  }

  .otp-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .theme-outlinedbtn {
    color: #2d53da;
    border: 1px solid #2d53da;
    background-color: transparent;
    border-radius: 10px;
    padding: 6px;
    width: 170px;
  }

  .theme-filldbtn {
    color: #ffffff;
    border: 2px solid #2d53da;
    background-color: #2d53da;
    border-radius: 10px;
    padding: 6px;
    width: 170px;
    text-align: center !important;
  }

  .theme-heading {
    font-size: 38px;
    font-weight: 600;
  }
  .form-control:focus,
  .form-select:focus {
    box-shadow: none !important;
  }
  .form-select {
    color: #636980;
    font-family: "DM Sans", sans-serif !important;
  }

  .filldbtn {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #1f243b;
    background-color: #1f243b;
    border-radius: 6px;
    padding: 6px 20px;
  }

  .form-btn {
    color: #2d53da;
    border: 1px solid #2d53da;
    background-color: transparent;
    border-radius: 12px;
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header-logo {
    width: 150px;
    margin-left: 1.5rem;
  }

  /* .ant-input {
    line-height: 1.99;
    font-family: "DM Sans", sans-serif !important;
  }
 
  .ant-form-item-label > label {
    font-family: "DM Sans", sans-serif !important;
} */

  .label-custom {
    color: #636980;
    font-size: 14px;
    font-weight: 500;
  }

  .label-agree {
    color: #636980;
    font-size: 16px;
    font-weight: 500;
  }

  .form-check-input {
    border: 1px solid #b6b9bcdb !important;
  }

  @media screen and (max-width: 768px) {
    .header-logo {
      width: 100px;
      margin: 0px;
    }
    .header-navbar {
      padding-left: 6% !important;
    }

    .form-btn {
      width: 50%;
    }

    .theme-outlinedbtn {
      width: 48%;
    }
    .theme-filldbtn {
      width: 50%;
    }
  }

  @media only screen and (min-width: 992px) {
    .hrmanage {
      display: none;
    }
  }
`;

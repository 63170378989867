import Styled from "styled-components";

const FeatureWrap = Styled.div`
 .stepper-conatin{
    padding: 10px 10px 10px 10px;
 }
 .image-container{
   padding-top:50px ;
 }

 /* .active {
  color: white !important;
} */

.feature-component{
  background-color: #1f243b;

}



/* .Step{
  color:white !important;
} */

.text1{
  font-size:28px;
  font-weight:400;
}

.content{
  color:  white;
  font-size:20px !important; 
  font-weight: 400;
}

.MuiStepLabel-label{
  color: white !important;
  font-size:30px
}
.MuiStepContent-root{
  border-left: 8px solid #08256c;
  margin-left: 22px !important;
}
.MuiStepConnector-lineVertical{
  border-left: 8px solid #08256c !important;
  margin-left: 10px !important;
} */
/* .label{
  color: white !important;
  font-size: 40px;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.feature-fadein {
  animation: fadeIn 5s ease-in-out;
}


.vertical-stepper {
  width: 300px; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}
.step {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.step:last-child {
  border-bottom: none;
}
// .step:hover {
//   background-color: #F0F0F0;
// }
.step.active {
  background-color: #E0E0E0;
}
.step-title {
  font-weight: bold;
}
.step-content {
  padding: 10px;
}

.MuiTypography-root {
  color: #ffffff;
}


@media screen and (max-width: 768px) {
  .mobilestepper-contain{
    padding-left:0rem !important;
  }
}

`;

export default FeatureWrap;

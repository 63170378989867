import Styled from "styled-components";

const HowtouseWrap = Styled.div`
.sub-head{
   font-size:32px;
   font-weight:500 !important;
}

.steps-contain{
   padding-top:10%;
   padding-bottom:10%;
   }

.content{
   animation: fadeImg 5s ease-in-out 
}

.text-list{
   font-family: "DM Sans", sans-serif !important;
   font-size: 18px;
   font-weight:400;
   
}

img {
    max-width: 90%;
    height: auto;
}


   @keyframes fadeTop {
      from {
        opacity: 0;
        transform: translateY(-70px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    .fade-top {
      animation: fadeTop 4s ease forwards;
    }

 @media  (max-width: 768px) {
   .theme-heading{
      font-size: 24px !important;
   }

   .sub-head{
      font-size:20px !important;
   font-weight:500 !important;
   }

   p{
      font-size: 16px !important;
      font-weight:400 !important;
   }

   img{
    max-width: 50%;
    height: auto;
}
 }

 

 @keyframes fadeImg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

export default HowtouseWrap;

import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import hrpeople from "../../Assests/HRpeople.svg";
import hrpolicies from "../../Assests/HRpolicies.svg";
import hrrecruitment from "../../Assests/HRrecruitement.svg";
import hrforms from "../../Assests/HRforms.svg";
import hrband from "../../Assests/HRband.svg";
import hrapprisal from "../../Assests/HRappraisal.svg";
import wpeople from "../../Assests/WPeople.svg";
import wrecruiment from "../../Assests/Wrecruitment.svg";
import wappraisal from "../../Assests/Wappraisal.svg";
import band from "../../Assests/Band.svg";
import wtemplate from "../../Assests/Formtemplate.svg";
import wpolicies from "../../Assests/Wpolicies.svg";
import wdocument from "../../Assests/Wdocuments.svg";
import gband from "../../Assests/gband.svg";
import people from "../../Assests/People..svg";
import recruitment from "../../Assests/Recruitment.svg";
import appraisal from "../../Assests/Appraisal.svg";
import policies from "../../Assests/Policies.svg";
import template from "../../Assests/gformtemplate.svg";
import document from "../../Assests/Documents.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styled from "styled-components";

const menus = [
  {
    menu: "People",
    title: "Manage People",
    description: `We provide you a centralized database where you can store all employee information securely. From basic contact details to employment history, qualifications, and performance evaluations, everything you need to know about your team members is easily accessible in one place. Our intuitive interface makes it simple to keep all employee records organized and up-to-date.`,
    image: hrpeople,
    activeicon: wpeople,
    inactiveicon: people,
  },
  {
    menu: "Recruitment",
    title: "Hire & Onboard",
    description:
    "Finding and hiring top talent has never been easier. Our comprehensive recruitment solution simplifies the entire hiring process, from creating job posts and posting them on multiple job portals to managing candidates and onboarding the best talent seamlessly.",
    image: hrrecruitment,
    activeicon: wrecruiment,
    inactiveicon: recruitment,
  },
  {
    menu: "Appraisal",
    title: "Foster Employee Development",
    description: `You can conduct performance appraisals and provide feedback to employees. Set goals, track progress, and facilitate constructive conversations to support employee development and drive organizational success.`,
    image: hrapprisal,
    activeicon: wappraisal,
    inactiveicon: appraisal,
  },
  {
    menu: "Policies",
    title: "Centralize Company Policies",
    description: `Keep all company policies organized and accessible in one convenient location with TymeplusHR Policies. From employee handbooks to HR guidelines and compliance documents, our platform ensures that everyone in the organization has easy access to the information they need to stay informed and compliant.`,
    image: hrpolicies,
    activeicon: wpolicies,
    inactiveicon: policies,
  },
  {
    menu: "Form Template",
    title: "Customize Your Forms",
    description: `Form Template helps you customize templates and create new forms to streamline across the organization. Whether it's collecting employee feedback, processing leave requests, or conducting performance reviews, our form template empowers you to create tailored solutions that meet their specific needs.`,
    image: hrforms,
    activeicon: wtemplate,
    inactiveicon: template,
  },
  {
    menu: "Documents",
    title: "Secure Document Storage",
    description: `Store and manage employee documents securely with TymeplusHR Documents. From contracts and certifications to performance reviews and training materials, our platform ensures that all employee documents are stored safely and accessible when needed.`,
    image: hrpeople,
    activeicon: wdocument,
    inactiveicon: document,
  },
  {
    menu: "Band",
    title: "Define Salary Bands",
    description: `Define salary bands based on years of experience and other criteria using TymeplusHR Band Range. Ensure fair and transparent compensation practices within your organization by establishing clear guidelines for salary ranges, allowing HR teams to make informed decisions when hiring and promoting employees.`,
    image: hrband,
    activeicon: band,
    inactiveicon: gband,
  },
];

export const HRmanage = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionChange = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <AccordionWrap>
      <div className="d-lg-none">
        <h3 className="pt-5 d-flex justify-content-center text-align-center color5 text1">
            HR Management
          </h3>
          </div>
      <Accordion
        activeKey={activeKey}
        onSelect={handleAccordionChange}
        className="hrmanage py-4 my-4"
      >
        {menus.map((item, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header
              className={activeKey === index.toString() ? "active" : "inactive"}
            >
              <img
                src={
                  activeKey === index.toString()
                    ? item.activeicon
                    : item.inactiveicon
                }
                className="pe-2"
              />
              {item.menu}
              {activeKey === index.toString() ? (
                <KeyboardArrowUpIcon className="arrow" />
              ) : (
                <KeyboardArrowDownIcon className="arrow" />
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div className="pb-4 pt-2">
                <h4 className="pb-3 color3 hrmange-title">{item.title}</h4>
                <p className="pb-3 color2 hrmange-content">
                  {item.description}
                </p>
                <img src={item.image} alt={item.menu} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </AccordionWrap>
  );
};


export const AccordionWrap = styled.div`
  .hrmange-title {
    font-size: 16px;
    font-weight: 500;
  }

  .hrmange-content {
    font-size: 16px;
    font-weight: 400;
  }

  .text1 {
    font-size: 24px;
    font-weight: 700 !important;
  }
`;


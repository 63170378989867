import styled from "styled-components";

export const HRWrap = styled.div`
position: relative;
  .text1 {
    font-size: 38px;
    font-weight: 700 !important;
  }

  .text2 {
    font-size: 30px;
    font-weight: 500 !important;
  }

  .text3 {
    font-size: 20px;
    font-weight: 400 !important;
    padding-right: 35%;
  }

  .hr-img{
    padding-right: 35%;
  }

  .text4 {
    font-size: 28px;
    font-weight: 400 !important;
  }

  .active {
    background-color: #2d53da; /* Change background color as desired */
    color: #ffffff !important; /* Change text color as desired */
    border-radius: 15px;
    box-shadow: 0px 4px 8px 0 rgba(45, 83, 218, 0.4),
      0px 6px 20px 0 rgba(45, 83, 218, 0.38);
  }

  .management {
    margin-top: 50%;
  }

  .menu-item {
    max-width: 300px;
    font-size: 18px;
    /* animation: mymove 5s 1; */
  }

  .menus-contain {
    padding-left: 35%;
  }

  .underline {
    text-decoration: none;
    position:sticky;
    top:70px;
  }

  .underline-text {
   
    text-decoration: underline;

    text-underline-offset: 5px;
    text-decoration-color: #2d53da61;
  }

  @media screen and (max-width: 768px) {
    .underline{
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
    }

  }
`;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import image from "../../../Assests/Landing Pic.svg";
import { HomerWrap } from "../style";
import { animateScroll as scroll } from "react-scroll";


export const Home = ({ setColor }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setColor(false);
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 100,
      smooth: "smooth",
    });
  };
  return (
    <HomerWrap>
      <Container fluid className="py-2 contain-margin" id="Home">
        <Row>
          <Col lg={6} md={12}>
            <h3 className="para py-4 text2">
              One-Stop Solution <br /> for{" "}
              <span>
                {" "}
                <ReactTyped
                  strings={["Organizational", "HR", "Time", "Task"]}
                  typeSpeed={100}
                  loop
                  backSpeed={50}
                  showCursor={true}
                  className="organi"
                />
              </span>
              <br />
              Management
            </h3>

            <p className="color2 text1 text2">
              TymeplusHR is a management software designed to empower
              organizations of all sizes to effectively manage their human
              resources, tasks, time, and more, all from one platform.
            </p>
            <div className="text2">
              <button
                className="theme-outlinedbtn my-4 mx-2"
                onClick={() => {navigate("/register"); scrollToTop()}}
              >
                Get Started
              </button>{" "}

              <button className="theme-filldbtn mx-2" onClick={() => {navigate("/bookademo"); scrollToTop()}}>Book a Demo</button>{' '}
            </div>
          </Col>
          <Col lg={6} md={12}>
            <Container className="img-contain">
              <img
                src={image}
                alt="logo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Container>
          </Col>
        </Row>
      </Container>
    </HomerWrap>
  );
};

import Styled from "styled-components";

const DataManagementWrap = Styled.div`
padding: 5%;
background: #e3e8fc;

.heading{
  font-size: 30px;
  text-align: center;
  color: #2d51d6;
  font-weight: 500;
}

.sub-heading{
  text-align: center;
}

.card{
  height: 100%;
  box-shadow:  0 0 10px rgba(0, 0, 0, 0.30);
  padding: 20px;
}

.card-heading{
  font-size: 22px;
  font-weight: 600;
}

`;

export default DataManagementWrap;

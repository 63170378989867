import React, { useState } from "react";
import { Col, Row, Form, InputGroup } from "react-bootstrap";
import { RegisterWrap } from "../style";
import logo from "../../../Assests/Frame 2147225291.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { countryList } from "../../datas/formdata";
import axios from "axios";
import { baseURL } from "../../../Services/api";
import { Spin, message, Alert } from "antd";
import { OTPModal } from "./OTP/otpmodal";
import { CreatePassword } from "../ForgetPassword/createpassword";

const schema = yup.object().shape({
  companyName: yup.string().required("Please complete this required field."),
  email: yup
    .string()
    .email("Please Enter valid Email")
    .required("Please complete this required field."),
});

export const SignUp = () => {
  const navigate = useNavigate();
  const [registerdata, setRegisterData] = useState({
    domain: "",
    employees: "",
    country: "",
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [domainstatus, setDomainStatus] = useState("");
  const [domainsuccess, setDomainSuccess] = useState();
  const [urlloader, setUrlLoader] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [active, setActive] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOnChange = (e) => {
    setRegisterData({ ...registerdata, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      ["Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
      ].includes(event.key);
    if (!isNumericKey) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/user/check-email`, {
        email: data.email,
      });
      if (
        response.data.status == true &&
        response.data.message != "EmailID Already Exists"
      ) {
        setEmail(data.email);
        setcompanyName(data.companyName);
        setphoneNumber(data.phoneNumber);
        setActive("domainpage");
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendOTP = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/user/send-otp`, {
        email: data.email,
      });
      if (response.data.status) {
        setActive("confirm-otp");
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOtpVerification = async (otp) => {
    setOtpError(false);
    if (otp.length < 6) {
      setOtpError(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`${baseURL}/user/verify-otp`, {
          email: email,
          otp: otp,
        });
        if (response.data.status) {
          messageApi.open({
            type: "success",
            content: "OTP verified successfully",
          });
          setActive("create-password");
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
          setOtpError(false);
        }
        setLoading(false);
       
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleResendOtpVerification = async (otp, setOtp) => {
    setOtp('');
    
    if (otp.length < 6) {
      setOtpError(true);
    } else {
      setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/user/send-otp`, {
        email: email,
        otp: otp,
      });
      if (response.data.status) {
        messageApi.open({
          type: "success",
          content: "OTP resend successfully",
        });
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  };

  const handleChangeDomain = async (e) => {
    const { name, value } = e.target;
    if (value.length === 0) {
      setDomainStatus("error");
      setDomainSuccess(false);
      return;
    }
    try {
      setUrlLoader(true);
      const payload = {
        domain: `${e.target.value}.tymeplushr.com`,
      };
      const response = await axios.post(
        `${baseURL}/user/check-domain`,
        payload
      );
      if (response.data.status === true) {
        setDomainStatus("success");
        setShowAlert(false);
        setDomainSuccess(true);
        setUrlLoader(false);
        setAlertMsg("");
      } else {
        setDomainStatus("error");
        setShowAlert(true);
        setAlertMsg("Domain Already Exists");
        setDomainSuccess(false);
        setUrlLoader(false);
      }
    } catch {
      setUrlLoader(false);
      setDomainSuccess(false);
    }
  };

  const handleCreatePassword = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/user/register`, {
        companyName: companyName,
        country: registerdata.country,
        domain: registerdata.domain + ".tymeplushr.com",
        email: email,
        employeeCount: registerdata.employees,
        firstName: "",
        interval: "month",
        lastName: "",
        monthlyCost: 0,
        password: data.password,
        paymentType: "free",
        productList: "",
        totalCost: 0,
        type: "company",
      });
      if (response.data.status) {
        navigate("/login");
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: response.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <RegisterWrap>
        {contextHolder}
        <div className="min-h-screen bgimage">
          <div className="form-contain">
            <Row className="justify-content-center ">
              <Col md={12}>
                <div className="rounded-xl shadow-lg overflow-hidden contain">
                  <Row>
                    <Col
                      className="text4 color4 d-none d-lg-block"
                      xs={12}
                      md={12}
                      lg={7}
                    >
                      <div className="text-start text-content">
                        <h1 className="pb-4">Don't Wait</h1>{" "}
                        <p>"Sign Up Now for a More Productive Tomorrow"</p>
                      </div>
                    </Col>
                    <Col
                      className="pt-2 d-flex flex-column bg-white mobileview-form"
                      xs={12}
                      md={12}
                      lg={5}
                    >
                      <img
                        src={logo}
                        alt="logo"
                        width="150"
                        className="mx-auto pt-2 pb-3 cursor-pointer"
                        onClick={() => navigate("/")}
                      />

                      {active === "create-password" ? (
                        <h1 className="text-center text-bold text3 color3 pt-2 pb-4">
                          Create Password
                        </h1>
                      ) : (
                        <h1 className="text-center text-bold text3 color3">
                          Sign Up
                        </h1>
                      )}

                      {active === "create-password" ? (
                        <>
                          <p className="text-center color2 text5 px-4 pb-3">
                            Enter and confirm your password to <br /> proceed
                            further
                          </p>
                          <Spin spinning={loading}>
                          <CreatePassword
                            handleCreatePassword={handleCreatePassword}
                          />
                          </Spin>
                        </>
                      ) : active === "domainpage" ? (
                        <Spin spinning={loading}>
                        <Form
                          className="px-4 mx-4 pb-4 userdetail-form"
                          onSubmit={handleSubmit(sendOTP)}
                        >
                          {showAlert && (
                            <Alert
                              className="alert_box"
                              message={alertMsg}
                              type="error"
                            />
                          )}
                          <Form.Label className="text1 color2">
                            You Domain
                          </Form.Label>
                          <InputGroup className="pb-3">
                            <Form.Control
                              required
                              placeholder=""
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="domain"
                              value={registerdata.domain}
                              onChange={(e) => {
                                handleChangeDomain(e);
                                handleOnChange(e);
                              }}
                            />
                            <InputGroup.Text id="basic-addon2">
                              .tymeplushr.com
                            </InputGroup.Text>
                          </InputGroup>
                          <Form.Group
                            className="pb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="text1 color2">
                              Number of Employees
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder=""
                              name="employees"
                              onChange={handleOnChange}
                              value={registerdata.employees}
                              onKeyDown={(e) => {
                                handleKeyDown(e);
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="pb-2">
                            <Form.Label className="text1 color2">
                              Country
                            </Form.Label>

                            <Form.Select
                              name="country"
                              onChange={handleOnChange}
                              value={country.employees}
                            >
                              <option value="" className="text1 color2">
                                Select Country
                              </option>{" "}
                              {/* Default empty option */}
                              {countryList?.map((country, index) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          <div className="text-center pt-4 mt-2 pb-2">
                            <div className=" mb-2">
                              <button
                                className="theme-outlinedbtn  prev-btn"
                                onClick={() => setActive("")}
                              >
                                Previous
                              </button>{" "}
                              <button className="theme-outlinedbtn ms-4">
                                Send OTP
                              </button>{" "}
                            </div>
                          </div>
                        </Form>
                        </Spin>
                      ) : active === "confirm-otp" ? (
                        <Spin spinning={loading}>
                        <OTPModal
                          handleOtpVerification={handleOtpVerification}
                          handleResendOtpVerification={
                            handleResendOtpVerification
                          }
                          setActive={setActive}
                          otpError={otpError}
                          setOtpError = {setOtpError}
                        />
                        </Spin>
                      ) : (
                        <Spin spinning={loading}>
                        <Form
                          className="px-4 mx-4 signup-form"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <Form.Group
                            className="py-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="text1 color2">
                              Company Name
                            </Form.Label>
                            <Form.Control
                              {...register("companyName")}
                              type="text"
                              placeholder=""
                              name="companyName"
                              className="placeholder-style"
                            />
                            <p className="error-msg pt-2">
                              {errors.companyName?.message}
                            </p>
                          </Form.Group>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="mb-3 text-start"
                          >
                            <Form.Label className="text1 color2">
                              Email ID
                            </Form.Label>
                            <Form.Control
                              {...register("email")}
                              type="email"
                              name="email"
                              placeholder=""
                              className="placeholder-style"
                            />
                            <p className="error-msg pt-2">
                              {errors.email?.message}
                            </p>
                          </Form.Group>

                          <Form.Group
                            className="pb-5 text-start"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="text1 color2">
                              Phone number (Optional)
                            </Form.Label>
                            <Form.Control
                              {...register("phoneNumber")}
                              className="placeholder-style"
                              placeholder=""
                              name="phoneNumber"
                              onKeyDown={handleKeyDown}
                            />
                            <p className="error-msg pt-2">
                              {errors.phoneNumber?.message}
                            </p>
                          </Form.Group>
                          <div className="text-center pt-2 pb-2">
                            <button className="form-btn">Continue</button>
                          </div>
                        </Form>
                        </Spin>
                      )}

                      <div className="text-center text2 pb-3 mb-4">
                        <p>
                          Already have an account?
                          <span
                            className="color5 cursor-pointer ps-1"
                            onClick={() => navigate("/login")}
                          >
                            Login
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </RegisterWrap>
    </>
  );
};

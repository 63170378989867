import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Strategicreporting from "../../Assests/1.svg";
import AdaptiveInterface from "../../Assests/4.svg";
import reporting from "../../Assests/Frame 2147225072.svg";
import tymeplusAI from "../../Assests/Frame 2147225074.svg";
import adaptiveinterface from "../../Assests/Frame 2147225075.svg";
import productivity from "../../Assests/Frame 564.svg";
import Integration from "../../Assests/Integration.svg";
import TymeplusAI from "../../Assests/Tymeplus AI.svg";
import integration from "../../Assests/Web inte.svg";
import Personalized from "../../Assests/Your personalized.svg";
import CircleConnector from "./CircleStepper";
import FeatureWrap from "./style";

const steps = [
  {
    id: 0,
    label: "Strategic Reporting",
    description: `Track your organization's performance with our robust reporting features. Generate detailed reports on various aspects of your operations, enabling you to make strategic decisions with confidence. At TymeplusHR, we believe that data is the key to unlocking your organization's full potential.`,
    image: `${reporting}`,
    mobimg: `${Strategicreporting}`,
  },
  {
    id: 1,
    label: "Your Personalized Workspace for Productivity",
    description:
      "With the TymeplusHR User Dashboard, managing your work life has never been easier. Take control of your time, track your progress, and stay on top of your schedule effortlessly. Here's what you can expect from our intuitive dashboard",
    image: `${productivity}`,
    mobimg: `${Personalized}`,
  },
  // {
  //   id: 2,
  //   label: "TymeplusHR AI 1.O",
  //   description: `With our Al technology, accessing information has never been easier. Whether you're a manager looking for real-time insights or an employee seeking answers to your queries, our Al-powered assistant has you covered.`,
  //   image: `${tymeplusAI}`,
  //   mobimg: `${TymeplusAI}`,
  // },
  {
    id: 2,
    label: "Adaptive Interface & Customization",
    description: `We understand that every role within your organization is unique, with its own set of responsibilities and requirements. That's why we've developed customizable employee dashboards that empower employers to tailor the user experience according to each role`,
    image: `${adaptiveinterface}`,
    mobimg: `${AdaptiveInterface}`,
  },
  {
    id: 3,
    label: "Integration at it's best",
    description: `We're committed to provide a flexible and customizable experience for our users. That's why we're excited to announce seamless integration with third-party applications, allowing you for a better workflow and streamline your processes
    <p class="custom-para">We provide you 2 of our best products to enhance you add your teams's productivity </p>`,
    image: `${integration}`,
    mobimg: `${Integration}`,
  },
];

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundImage:
    "linear-gradient( 136deg, #2d53da 0%, #2d53da 50%,white 100%)",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0.3,
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #2d53da 0%, #2d53da 50%,white 100%)",
    boxShadow: "0 4px 40px 0 #2d53da",
    width: 50,
    height: 50,
    opacity: 1,
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #2d53da 0%, #2d53da 50%,white 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    />
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export const Features = ({
  setColor,

  isHRManagementVisible,
}) => {
  const featuresRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setColor(true);
          } else {
            setColor(false);
          }
        });
      },
      { threshold: 0.5 }
    );
    if (featuresRef.current) {
      sectionObserver.observe(featuresRef.current);
    }

    return () => {
      if (featuresRef.current) {
        sectionObserver.unobserve(featuresRef.current);
      }
    };
  }, [featuresRef]);

  return (
    <>
      <FeatureWrap>
        <section className="py-4 mb-4 feature-component" ref={featuresRef}>
          <div className="ps-5 mobilestepper-contain">
            <CircleConnector
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isHRManagementVisible={isHRManagementVisible}
            />
          </div>
        </section>
      </FeatureWrap>
    </>
  );
};

export default Features;

import React, { useState, useRef } from "react";
import { TermsWrap } from "./style";
import { Col, Row } from "react-bootstrap";

const PrivacyPolicy = ({ headerRef }) => {
  const [active, setActive] = useState(0);
  const privacyContentRef = useRef(null);
  const menus = [
    "Personal Information",
    "Sharing of your Information",
    "Data Retention",
    "Your Rights associated with your information",
    "Changes to the privacy policy",
  ];

  const content = [
    {
      value:
        "We generally collect and process the following types of Personal Information:",
      list: [
        {
          title: "Personal Information",
          value:
            "We generally collect and process the following types of Personal Information: Personal Information which is being gathered through the Service consists of any personal details provided consciously and voluntarily by our customer (Employer), end user or the Customer’s administrator or through your use of the TymeplusHR platform. This may include your name (first and last), nickname, birthdate, gender, nationality, job title, phone number(s), date you first started working for your employer, department you work in, employee ID/ national security number, address, country, city, postcode, family status, spouse’s and other dependents name, gender and birth date, your bank account details (bank name, account number, branch address), details regarding your salary and work (pay period, payment frequency, base salary, gross salary, overtime, bonuses, commissions, statutory payments such as sick, maternity/paternity leave, salary payment currency, credential regarding the right to work in your jurisdiction, tax code, emergency contact details (name, relation, phone number(s), email address(es), city, country, post code), termination date, termination reason, probation end date, status in the system and in the workplace, IP address and other unique identifiers, user’s information relating to tax declarations, information the customer chooses to collect and other information user may choose to provide to TymeplusHR and to its employee.",
        },
        {
          title: "Location Information",
          value:
            "We do not ask you for, access, or track any location-based information from your mobile device at any time while downloading or using the Mobile Apps. However, if you are using the TymeplusHR Mobile App, you acknowledge, consent and agree that your employer would be enable location tracking technology for time keeping purposes. The GDPR legal basis for processing this information is the contractual obligation to your employer to perform the Services.",
        },
        {
          title: "Attendance Logs",
          value:
            "If our Customer (Employer) chose to utilize the Time and attendance module, we shall retain and process biometric device logs of the users for the purposes of calculating attendance and payroll using the rules configured by the Customer. The information collected consists of employee or attendance id along with timestamps of punches (in and out entries).",
        },
        {
          title: "Contact Information",
          value:
            "When you express an interest in obtaining additional information about the Services, the Site, or Mobile Apps, TymeplusHR may ask you to provide your personal contact information, such as your name, email address, and phone number. You agree, consent and acknowledge that this information is used to communicate with you by responding to your requests, comments and questions. The GDPR legal basis for processing this information is the legitimate interest in communicating with you and answering your questions.",
        },
        {
          title: "Device Information",
          value:
            "When using the Mobile Apps, we may request access to your device’s camera and photo storage. This allows you to take and upload pictures and such access would only be used in ways you choose. You may at any time revoke access at the device level. We do not access your device’s camera and photo storage without your permission. We use mobile analytics software to allow us to better understand the functionality of our Mobile Apps on your phone. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information we store within the analytics software to any Personal Information you submit within the Mobile Apps. When you download and use the Mobile Apps, we automatically collect your device information such as operating system version, type, hardware usage statistics, etc. The GDPR legal basis for processing this information is the contractual obligation to your employer to perform the Services.",
        },
        {
          title: "Data Collected as a Service Provider",
          value:
            "As a service provider, TymeplusHR  only collects information as per the Customer (employer’s) requirement. our Subscription Agreement governs the delivery, access, and use of the Services and Mobile Apps, including the processing of Personal Information and data submitted through Services accounts. The Customer (e.g., your employer) controls their Platform and any associated client data. If you have any questions about specific Platform settings, the processing of Personal Information in the Platform, or its privacy practices, please contact the Customer administrator of the Platform you use. Customer data shall be used by TymeplusHR in accordance with the Customer’s instructions, applicable terms in the Service Agreement, Customer’s use of Services functionality, and as required by applicable law. Under applicable GDPR, TymeplusHR is a processor of Customer data and Customer is the controller.",
        },
      ],
    },
    {
      value: "",
      list: [
        {
          title: "Third Party Services",
          value:
            "At times, you may be able to access other Third-Party Services/websites through the Site, for example by clicking on links to those Third-Party Services from within the Site. We are not responsible for the privacy policies and/or practices of these Third-Party Services, and you are responsible for reading and understanding those Third-Party Services’ privacy policies.",
        },
        {
          title: "Information Shared with Our Service Providers",
          value:
            "You agree, consent and acknowledge that we may share your information with third parties who provide services to us. These third parties are authorized to use your Personal Information only as necessary to provide these services to us. These services may include the provision of (i) email services to send marketing communications, (ii) mapping services, (iii) customer service or support, and (iv) providing cloud computing infrastructure.",
        },
        {
          title: "Information Shared with Our Sub-Processors",
          value:
            "We employ and contract with people and other entities that perform certain tasks on our behalf and who are under our control such as an email service provider to send emails on our behalf, mapping service providers, and customer support providers our “Sub-Processors”). We may need to share Personal Information with our Sub-Processors in order to provide Services to you. Unless specified otherwise, our Sub-Processors do not have any right to use Personal Information or other information We share with them beyond what is necessary to assist us..",
        },
        {
          title: "Information Disclosed Pursuant to Business Transfers",
          value:
            "In some cases, we may choose to buy or sell assets. In these types of transactions, user information is typically one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your Personal Information as set forth in this Privacy Policy. You will be notified via email and/or a prominent notice on our site of any change in the legal owner or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.",
        },
        {
          title:
            "Information Disclosed for Our Protection and the Protection of Others",
          value:
            "In certain situations, we may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We also reserve the right to access, read, preserve, and disclose any information as We reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request (ii) enforce this Privacy Policy, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security, or technical issues; (iv) respond to user support requests; or (v) protect our rights, property, or safety. This includes exchanging information with other companies and organizations for fraud protection and spam/malware prevention. We require all third parties to respect the security of your Personal Information and to treat it in accordance with applicable laws. We do not allow third party service providers and Sub-Processors We share your Personal Information with to use it for their own purposes and only permit them to process your Personal Information for specified purposes in accordance with our instructions. Except as set forth above, you will be notified when your Personal Information is shared with third parties, and will be able to prevent the sharing of this information. Unless We otherwise have your consent, we will only share your Personal Information in the ways that are described in this Privacy Policy.",
        },
        {
          title:
            "Information Disclosed for Our Protection and the Protection of Others",
          value:
            "TymeplusHR has developed a functionality that allows its customers to connect their google account with our products. Connecting your Google account to your TymeplusHR products allows TymeplusHR to view your personal information mentioned in your Google Account and to transmit the Service Data to TymeplusHR products that you integrate with your G-Account. TymeplusHR use and transfer of information received from Google APIs shall adhere to Google API Services User Data Policy's App's, including the Limited Use requirement.",
        },
      ],
    },
    {
      value: (
        <>
          <p className="content">
            Any Customer may request information regarding the storage and
            retention of data (“Audit”) by contacting us. TymeplusHR shall make
            reasonable efforts to respond to the Audit in a reasonable time and
            subject to applicable law and to the protection of TymeplusHR trade
            secrets (Customer’s personnel may be required to execute a
            non-disclosure agreement).
          </p>
          <p className="content">
            TymeplusHR will retain data it processes on behalf of its customers
            only for as long as required to provide the Service to its Customers
            and as necessary to comply with its legal obligations, resolve
            disputes and enforce its agreements. The data in TymeplusHR is
            backed up for system continuity purposes and each backup file may be
            stored for 30 days.
          </p>
          <p className="content">
            After a termination of services by a customer, an automated process
            will begin that permanently deletes the data in the next cycle.
          </p>
          <p className="content">
            You agree, consent and acknowledge that K TymeplusHR collects and
            retains metadata and statistical information concerning the use of
            the service which are not subject to the deletion procedures in this
            policy and may be retained by TymeplusHR for no more than required
            to conduct its business. Some data may be retained also on our
            third-party service providers’ servers in accordance with their
            retention policies. You will not be identifiable from this retained
            metadata or statistical information.
          </p>
          <p className="content">
            Customer may retain Personal Information and other Data about an end
            user which the Controller owns and the end user may have no access
            to. If you have any questions about the right of the Customer to
            retain and process your Personal Information you should raise this
            directly with the Customer. You hereby agree not to assert any claim
            against TymeplusHR in this regard and waive any rights regarding
            such Data and Personal Information including the right to view and
            control such Data and Information.
          </p>
          <p className="content">
            Anonymized aggregated data may be retained by TymeplusHR for as long
            it is required to provided its services. Contracts and billing
            information may be retained as required by TymeplusHR for at least 5
            years from termination or expiration of the relationship with the
            applicable Customer or party.
          </p>
        </>
      ),
      list: [
        {
          title: "Where do we store your Data?",
          value: (
            <>
              <p className="content">
                The Data we collect is hosted on Amazon Web Servers in Central
                India, Singapore, Africa, North Europe, South East Asia,
                Australia. They provide advanced security features and is
                compliant with ISO 27001 and other privacy and security
                standards.
              </p>
              <p className="content">
                Therefore, in providing your Personal Information to TymeplusHR,
                your Personal Information will be sent to Central India,
                Singapore, Africa, North Europe, South East Asia, Australia (or
                otherwise outside of the European Union), where the local
                applicable law may provide you with less protection than under
                European Union law. However, any transfer of Personal
                Information from the European Union to these other locations
                will be strictly in accordance with applicable EU GDPR.
              </p>
              <p className="content">
                Where your Data is transferred outside of the EEA (European
                Economic Area), we will take all steps reasonably necessary to
                ensure that your Data is subject to appropriate safeguards, such
                as relying on a recognized legal adequacy mechanism, and that it
                is treated securely and in accordance with this privacy policy.
              </p>
            </>
          ),
        },
        {
          title: "Security and storage of information",
          value: (
            <>
              <p className="content">
                We take great care in implementing, enforcing and maintaining
                the security of the Service, and our users’ Personal
                Information. TymeplusHR implements, enforces and maintains
                security policies to prevent the unauthorized or accidental
                access to or destruction, loss, modification, use or disclosure
                of personal data and monitor compliance of such policies on an
                ongoing basis.
              </p>
              <p className="content">
                TymeplusHR limits access to personal data to those of its
                personnel who: (i) require access in order for TymeplusHR to
                fulfil its obligations under this Privacy Policy and agreements
                executed with TymeplusHR and (ii) have been appropriately and
                periodically trained on the requirements applicable to the
                processing, care and handling of the Personal Information (iii)
                are under confidentiality obligations as required under
                applicable law. TymeplusHR takes steps to ensure that its staff
                who have access to personal data are honest, reliable, competent
                and periodically properly trained.
              </p>
              <p className="content">
                TymeplusHR shall act in accordance with its policies to promptly
                notify Customer in the event that any personal data processed by
                TymeplusHR on behalf of Customer is lost, stolen, or where there
                has been any unauthorized access to it subject to applicable law
                and instructions from any agency or authority. Furthermore,
                TymeplusHR undertakes to co-operate with Customer in
                investigating and remedying any such security breach. In any
                security breach involves Personal Information, TymeplusHR shall
                promptly take remedial measures, including without limitation,
                reasonable measures to restore the security of the Personal
                Information and limit unauthorized or illegal dissemination of
                the Personal Information or any part thereof.
              </p>
              <p className="content">
                The Service may, from time to time, contain links to external
                sites. We are not responsible for the operation, privacy
                policies or the content of such sites.
              </p>
            </>
          ),
        },
      ],
    },
    {
      value: (
        <>
          <p className="content">
            In certain countries (for example in the UK and EU) you may have the
            right under applicable local law to request access, change or delete
            certain personal data that we hold in relation to you, or to object
            to or restrict the processing of certain personal information,
            receive personal information in a usable electronic format and
            transmit it to a third party (also known as the right of data
            portability), and lodge a complaint with a local data protection
            authority.{" "}
          </p>
          <p className="content">
            We will contact you if we need additional information from you in
            order to honour your requests.{" "}
          </p>
          <p className="content">
            If you want to update the information that we hold about you or if
            you no longer desire our Service, you may correct, delete
            inaccuracies, or modify the information we hold by sending an
            inquiry to our email address info@tymeplusHR.com. Given the nature
            of the services that we provide we may have shared your personal
            information with employers and other third parties. You may need to
            make requests to such third parties in accordance with the
            instructions in their privacy policies if you require them to update
            the records that they hold about you. If you want to discuss or
            exercise any of your rights under this policy, please contact us at
            info@tymeplusHR.com.{" "}
          </p>
        </>
      ),
      list: [],
    },
    {
      value: (
        <>
          <p className="content">
            The terms of this Privacy Policy will govern the use of the Service
            and any information collected in connection therewith, however,
            TymeplusHR may amend or update this Privacy Policy from time to
            time. The most current version of this Privacy Policy will always be
            posted at: https://www.tymepluhr.com/privacy-policy. Unless
            otherwise agreed with the Customer, we will endeavour to provide
            notice of material changes to this policy on the homepage of the
            website and (if applicable) via an e-mail. Such material changes
            will take effect seven (7) days after such notice was provided on
            our website or sent by email. Otherwise, all other changes to this
            Privacy Policy are effective as of the stated “Last Revised” date
            and your continued use of Services will constitute your active
            acceptance of, and agreement to be bound by, the changes to the
            Privacy Policy.
          </p>
          <p className="content">
            If you have any questions (or comments) concerning this Privacy
            Policy, you are welcome to send us an email or otherwise contact us
            at support@tymeplushr.com and we will make an effort to reply within
            a reasonable timeframe.
          </p>
        </>
      ),
      list: [],
    },
  ];

  const marginOffset = 20; // Adjust this value as per your requirement

  const handleMenuClick = (index) => {
    setActive(index);
    const headerHeight = headerRef.current.clientHeight;
    const contentElement = document.getElementById(
      menus[index].replace(/\s+/g, "-").toLowerCase()
    );
    const contentPosition =
      contentElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: contentPosition - headerHeight - marginOffset,
      behavior: "smooth",
    });
  };

  console.log("content-->", content[0]);
  return (
    <TermsWrap>
      <div className="heading-box">
        Privacy Policy
        <div class="circle d-none d-md-block"></div>
        <div class="circle d-none d-md-block"></div>
      </div>
      <div className="my-5">
        <Row>
          <Col md={4}>
            <div className="menus">
              <ol className={`custom-ol `}>
                {menus.map((label, index) => (
                  <a
                    href={`#${label.replace(/\s+/g, "-").toLowerCase()}`}
                    onClick={(event) => {
                      event.preventDefault();
                      handleMenuClick(index);
                    }}
                  >
                    <li
                      className={` ${index === active && "active"}`}
                      key={index}
                    >
                      {index + 1}
                      {". "}
                      {label}
                    </li>
                  </a>
                ))}
              </ol>
            </div>
          </Col>
          <Col className="privacy-content" md={8} ref={privacyContentRef}>
            <p className="content">
              At Foresyt Limited (including its subsidiaries or affiliated
              companies, henceforth also referred as ‘TymeplusHR’, ‘we’ or the
              ‘Company’), we understand that you are trusting us with
              confidential information and we believe that you have a right to
              know our practices regarding the information we may collect and
              use when you use our service or interact with us in any manner.
              TymeplusHR is a cloud-based web platform that enables
              organizations to manage their human resources. TymeplusHR Mobile
              Apps are also part of the same offering. In addition, TymeplusHR
              also operates the TymeplusHR.com website. A user may be either an
              entity, for example an employer which has executed an agreement
              with TymeplusHR or with TymeplusHR’s resellers or distributors who
              provide TymeplusHR’s services (“Customer “) or a Customer’s users
              for example a Customer’s employees, of the Services or users of
              the Website (“end user(s) “) (Customer and end user shall
              collectively be referred to as “users” or “you “).
            </p>
            <p className="content">
              This Privacy Policy describes the policies and procedures of
              TymeplusHR on the collection, use, access, correction, and
              disclosure of your personal information on TymeplusHR.com (the
              “Site”) and our Mobile Apps. This privacy policy does not relate
              to any personal information that TymeplusHR collects on behalf of,
              or under the direction, of its clients. When you log on to the
              Site you will have access to the privacy policy of the
              employer/prospective employer that is a client of TymeplusHR,
              which shall explain how they process your personal information and
              your rights in relation to such information. We may be asked by
              our clients to amend, update, or delete your personal information
              on behalf of our clients in which case we will do so in accordance
              with the terms of our contract with our clients.
            </p>
            <p className="content">
              Your personal information will include any information which,
              either alone or with other data, is reasonably available to us and
              relates to you (“Personal Information”). This Privacy Policy also
              covers any of your Personal Information which is provided to us
              and which is used in connection with the marketing of the
              services, features or content we offer (the “Services”) to our
              Clients and/or the support that we may give you in connection with
              the provision of our Services and the Mobile Apps.
            </p>
            <p className="content">
              This Privacy Policy does not apply to any third-party applications
              or software that can be accessed from the Site, the Services or
              the Mobile Apps, such as external applicant tracking systems,
              social media websites or partner websites (“Third Party
              Services”).
            </p>
            <p className="content">
              By using our Services, you acknowledge you have read and
              understood this privacy policy. For the purposes of EU GDPR
              (General Data Protection Regulation), the data controller of the
              data processed through the Service is the customer of TymeplusHR
              who makes available and permits end users to access and use the
              service or anyone on its behalf. For data collected directly on
              its website (for marketing and communication purposes), TymeplusHR
              is the “Data Controller”.
            </p>
            <h3 className="privacy-heading">
              Information we collect and how we use it
            </h3>
            {menus.map((data, index) => (
              <div id={data.replace(/\s+/g, "-").toLowerCase()} key={index}>
                {/* <p className="title">
                  {index + 1}
                  {". "}
                  {data}
                </p> */}
                {/* {content[index]?.map((privacy) => ( */}
                <div>
                  <p className="privacy-subheading">{data}</p>
                  <p className="content">{content[index]?.value}</p>
                  <div>
                    {content[index]?.list.map((privacy) => (
                      <>
                        <p className="content">
                          <b className="sub-heading">{privacy?.title}</b> {privacy?.value}
                        </p>
                      </>
                    ))}
                  </div>
                </div>
                {/* ))} */}
                <p className="content"></p>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </TermsWrap>
  );
};

export default PrivacyPolicy;

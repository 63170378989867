import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import styled from "styled-components"

export const Scrollwrapper = () => {
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 100,
      smooth: "smooth",
    });
  };
  return (
    <ScrollWrapper>
      {isVisible && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          <KeyboardDoubleArrowUpIcon/>
        </button>
      )}
    </ScrollWrapper>
  );
};

 const ScrollWrapper = styled.div`
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color:  #2d53da;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  outline: none;
}
`
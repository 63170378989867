import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { RegisterWrap } from "../style";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export const CreatePassword = ({ handleCreatePassword, password, setPassword, confirmPassword, setConfirmPassword }) => {
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    if (event.target.value.length < 8) {
      setPasswordError("Password must be 8 characters at minimum");
    } else {
      setPasswordError("");
    }
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value !== password) {
      setConfirmPasswordError("Confirm Password must match password");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!password) {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
    } else {
      setConfirmPasswordError("");
    }
    if (
      password &&
      confirmPassword &&
      !passwordError &&
      !confirmPasswordError
    ) {
      handleCreatePassword(password);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <RegisterWrap>
        <Form className="px-4 mx-4 pb-4 confirm-form" onSubmit={handleSubmit}>
          <Form.Group controlId="forgotPassword" className="pb-2">
            <Form.Label type="password" className="text1 color2">
              New Password
            </Form.Label>

            <InputGroup>
              <Form.Control
                value={password}
                onChange={handleChangePassword}
                name="password"
                placeholder=""
                type={showPassword ? "text" : "password"}
              />
              <InputGroup.Text
                className="cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </InputGroup.Text>
            </InputGroup>

            <span className="error-msg pt-2">{passwordError}</span>
          </Form.Group>
          <Form.Group controlId="confirmPassword" className="pb-2">
            <Form.Label type="password" className="text1 color2">
              Confirm Password
            </Form.Label>

            <InputGroup>
              <Form.Control
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                name="confirmPassword"
                placeholder=""
                type={showConfirmPassword ? "text" : "password"}
              />
              <InputGroup.Text
                className="cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </InputGroup.Text>
            </InputGroup>

            <span className="error-msg pt-2">{confirmPasswordError}</span>
          </Form.Group>
          <div className="text-center pt-4 pb-2">
            <button className="form-btn">Continue</button>
          </div>
        </Form>
      </RegisterWrap>
    </>
  );
};

// import './App.css';
import { Main } from './Components/Layout/Main';
import { Routers } from './Router';
import { Globalstyle } from './Styles/GlobalStyles';

function App() {
  return (
    <Globalstyle>
    <div className="App">
   <Routers/>
    </div>
    </Globalstyle>
  );
}

export default App;

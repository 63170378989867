import styled from "styled-components";
import bgdemo from "../../Assests/Bookademo.svg";

export const DemoWrap = styled.div`
  .demo-bgimg {
    background-image: url(${bgdemo});
    background-size: cover;
    padding-right: 8rem;
    padding-left: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .text1 {
    font-size: 24px;
    font-weight: 500;
    color: #2a3a74;
  }

  .text2 {
    font-size: 50px;
    font-weight: 600;
    color: #2d53da;
    line-height: 75px;
  }

  .list-style {
    font-size: 24px;
    font-weight: 400;
    color: #1f243b;
    line-height: 36px;
  }

  .error-msg {
    color: #f2545b;
    font-size: 14px;
    font-family: "DM Sans", sans-serif !important;
  }

  .shadow-box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background-color: white;
  }

  .demo-contain {
    padding-left: 20%;
    padding-top: 15%;
  }

  .demo-fullcontain {
    background: linear-gradient(
      21deg,
      rgba(176, 205, 249, 1) 0%,
      rgba(247, 251, 255, 1) 26%,
      rgba(238, 247, 255, 1) 73%,
      rgba(155, 194, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B0CDF9",endColorstr="#9BC2FF",GradientType=1);
  }

  .demo-mobbgimg {
    background-image: url(${bgdemo});
    background-size: cover;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .text2-mob {
    font-size: 30px;
    font-weight: 700;
    color: #2d53da;
    line-height: 50px;
  }

  .demo-containmob {
    display: flex;
    justify-content: center;
  }

  .list-stylemob {
    padding-top: 10px;
  }

  .demo-fulltext {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

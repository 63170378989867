import React from "react";
import { Col, Row } from "react-bootstrap";
import DataManagementWrap from "./style";

const DataManagement = () => {
  const data = [
    {
      title: "Cloud",
      content:
        "We utilize Amazon Web Services to power TymeplusHR, leveraging the worlds most comprehensive and widely adopted cloud infrastructure. AWS ensures the security and fortification of our systems through a range of measures, encompassing physical, operational, and software protocols.",
    },
    {
      title: "Data Encryption",
      content:
        "We employ robust encryption techniques to protect your data from unauthorized access during transmission and storage. Your Information is encrypted both in transit and at rest, ensuring that it remains secure at all times.",
    },
    {
      title: "Access Controls",
      content:
        "We implement strict access controls to regulate who can access your data within our system. Access is granted only to authorized personnel who require it to perform their duties, and all access is logged and monitored for security purposes.",
    },
    {
      title: "Data Backup",
      content:
        "We maintain regular backups of your data to prevent data loss in the event of hardware. failure, natural disasters, or other unforeseen circumstances. Our robust backup and recovery procedures ensure that your data remains accessible and intact at all times.",
    },
    {
      title: "Data Retention",
      content:
        "We follow clear data retention policies to ensure that your data is retained only for as long as necessary for the purposes for which it was collected. Once data reaches the end of its retention period, it is securely deleted from our systems in accordance with our retention policies.",
    },
    {
      title: "User Education and Training",
      content:
        "We provide comprehensive training and resources to our employees to ensure they understand their roles and responsibilities in safeguarding your data. Our employees are trained on data protection policies. security protocols, and best practices to minimize the risk of data breaches.",
    },
  ];

  return (
    <DataManagementWrap>
      <div className="heading">Data Management & protection</div>
      <div className="sub-heading p-4 ">
        we prioritize the security and integrity of your data. We understand the
        importance of safeguarding sensitive information and are committed to
        maintaining the highest standards of data management and protection.
      </div>
      <div>
        <Row>
          {data.map((value) => (
            <Col className="p-3" lg={4} md={6}>
              <div className="card">
                <p className="card-heading">{value.title}</p>
                <div>{value.content}</div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </DataManagementWrap>
  );
};

export default DataManagement;

import Styled from "styled-components";

const PricingWrap = Styled.div`

.slick-arrow{

  display: none !important;

}

.slick-dots{
  display: none !important;
  font-size: 20px;
  position: relative;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #00f !important;
    font-size: 15px;
}

.slick-dots li button:before{
  color: white !important;
  font-size: 15px !important;
}

.card-efect{ box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-sub{
    font-size: 18px;
    font-weight: 500;
}

.card-text{
    font-size: 30px;
    font-weight: 700;
}

.card-month{
    font-size: 16px;
    font-weight: 700;
}

.card-title{
    font-size: 28px;
    font-weight: 700;
}
.pricing-card{
    border-radius:15px;
    box-shadow: 0 0 10px #2d53da;
    margin-right: 30px;
    transition: 0.5s ease;
}

.theme-heading{
  padding-top:9% !important;
}

.pricing-contain{
  padding-left:10%;
  padding-right:10%;
}



@keyframes rainbowAni {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 768px) {
  .pricing-contain{
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    padding-bottom: 0rem;
margin-bottom: 0rem !important;

  }

  .theme-heading {
    font-size: 24px !important;
    font-weight: 600;
}

.slick-arrow{

display: none !important;

}

.slick-dots{
display: block !important;
}
.pricing-card{
  margin-right: 5px;
  margin-left: 5px;
}

.pricing{
padding-bottom: 0rem;
margin-bottom: 0rem !important;
padding-bottom: 0rem !important;
}

.theme-outlinedbtn{
  width: 60% !important;
}

/* .active-slide{
  transform: scale(1.04);
  border-radius: 15px;
} */

.inactive-slide{
  transform: scale(0.9);
}

.slick-list{
  overflow: none !important;
}

.slick-slider{
  display: ;
}


}



`;

export default PricingWrap;
